import image from "../../../images/ic_package_scan.png";
import {FormattedMessage} from "react-intl";
import React from "react";

export const ReceiveContainerResponseCode = {
    INVALID_TRACKING_ID: "INVALID_TRACKING_ID",
    ALREADY_PROCESSED: "ALREADY_PROCESSED",
    AT_WRONG_STATION: "AT_WRONG_STATION",
    NO_PATH_PLAN: "NO_PATH_PLAN",
    UNEXPECTED_STATUS: "UNEXPECTED_STATUS"
}
export const ResponseStatus = {
    SUCCESS: "SUCCESS",
    WARNING: "WARNING",
    ERROR: "ERROR"
}
export const ReceiveContainerInit = {
    primaryTitle: {
        title: <FormattedMessage id="receive_scan_container_label"/>,
        type: "Bold"
    },
    secondaryTitle: {
        title: <FormattedMessage id="scan_container_desc"/>
    },
    image: image
}