import React from "react"
import {FormattedMessage} from "react-intl";

export const ReconciliationStatus = {
    OUT_ON_ROAD: "OUT_ON_ROAD",
    RETURNING_TO_STATION: "RETURNING_TO_STATION",
    DELIVERED: "DELIVERED",
    MISSING: "MISSING",
    RETURNED: "RETURNED",
    MISSING_SKIPPED: "MISSING_SKIPPED",
    RESOLVED: "RESOLVED",
    DELIVERED_BY_RESOLVING: "DELIVERED_BY_RESOLVING",
    BULK_RETURNING_TO_STATION: "BULK_RETURNING_TO_STATION",
    SKIPPED: "SKIPPED",
    ASSIGNED_NOT_PICKED_UP: "ASSIGNED_NOT_PICKED_UP",
    PARTIALLY_DELIVERED: "PARTIALLY_DELIVERED",
    ALL: "ALL"
}
export const UnresolvedPackageStatus = [
    ReconciliationStatus.RETURNING_TO_STATION, ReconciliationStatus.OUT_ON_ROAD, ReconciliationStatus.MISSING
]

export const DOLPHIN_APP = "DOLPHIN_APP"

export const createDriverSummarySnapshot = (data) => {
    let buckets = [
        {
            description: <FormattedMessage id={"returning_packages"} />,
            title: `${data[ReconciliationStatus.RETURNING_TO_STATION].length}`
        },
        {
            description: <FormattedMessage id={"on_road_packages"} />,
            title: `${data[ReconciliationStatus.OUT_ON_ROAD].length}`
        },
        {
            description: <FormattedMessage id={"delivered_packages"} />,
            title: `${data[ReconciliationStatus.DELIVERED].length}`
        },
        {
            description: <FormattedMessage id={"missing_packages"} />,
            title: `${data[ReconciliationStatus.MISSING].length + data[ReconciliationStatus.MISSING_SKIPPED].length}`
        }
    ]

    if (data[ReconciliationStatus.BULK_RETURNING_TO_STATION] && data[ReconciliationStatus.BULK_RETURNING_TO_STATION].length > 0) {
        buckets.push({
            description: <FormattedMessage id={"bulk_dropoff"} />,
            title: data[ReconciliationStatus.BULK_RETURNING_TO_STATION].length.toString()
        })
    }
    return buckets
}

export const createDriverSummarySnapshotAfterDebrief = (data) => {
    let buckets = [
        {
            description: <FormattedMessage id={"returned_packages"} />,
            title: `${data[ReconciliationStatus.RETURNED].length}`
        },
        {
            description: <FormattedMessage id={"delivered_packages"} />,
            title: `${data[ReconciliationStatus.DELIVERED].length}`
        },
        {
            description: <FormattedMessage id={"skipped_packages"} />,
            title: `${data[ReconciliationStatus.SKIPPED].length}`
        },
        {
            description: <FormattedMessage id={"missing_packages"} />,
            title: `${data[ReconciliationStatus.MISSING].length + data[ReconciliationStatus.MISSING_SKIPPED].length}`
        }
    ]

    if (data[ReconciliationStatus.BULK_RETURNING_TO_STATION] && data[ReconciliationStatus.BULK_RETURNING_TO_STATION].length > 0) {
        buckets.push({
            description: <FormattedMessage id={"bulk_dropoff"} />,
            title: data[ReconciliationStatus.BULK_RETURNING_TO_STATION].length.toString()
        })
    }
    return buckets
}