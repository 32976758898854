import React, {useContext, useEffect} from "react";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import "../../index.css"
import {AppContext} from "../appContext/context/AppContext";
import {DebriefDriverContext} from "../debriefDriver/context/DebriefDriverContext";
import {HomePageInit} from "./constants";
import {interceptBackButton, interceptBackButtonStop} from "../Handler/BackPressHandler";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {PlayerContext} from "../player/PlayerContext";

const Homepage = () => {
    const {appActions} = useContext(AppContext);
    const {debriefDriverActions} = useContext(DebriefDriverContext);
    const {statePlayer} = useContext(PlayerContext);

    const onBackPressed = () => {
        NativeMeshInteractor.exitModule();
    }

    useEffect(() => {
        statePlayer.isTaskSelected = false;
        appActions.resetAppContext()
        debriefDriverActions.resetDebriefDriverContext()
        appActions.setBackPress(onBackPressed)
        interceptBackButton();
        return () => {
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])

    return (
        <TaskView {...HomePageInit} />
    );
}

export default Homepage;