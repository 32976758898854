import React, {createContext, useReducer} from "react";
import { PlayerReducer } from './PlayerReducer';

export const PlayerContext = createContext();

const PlayerContextProvider = (props) => {

    const initState = {
        isInitialized: true,
        isTaskSelected: false,
        isFirstPackage: true,
        isManual: false,
        helpEnabled: false,
        backEnabled: false,
        backState: undefined,
        currentState: undefined,
        packageState: null,
        manualHelpEnabled: false,
        enabledSideline: false
    };

    const [statePlayer, dispatchPlayer] = useReducer(PlayerReducer, initState);

    return (
        <PlayerContext.Provider value={{statePlayer, dispatchPlayer}} >
            {props.children}
        </PlayerContext.Provider>
    );
}

export default PlayerContextProvider;
