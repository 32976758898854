import React, {Component} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import Homepage from "./home/Homepage";
import DebriefDriverView from "./debriefDriver/views/DebriefDriverView";
import SearchDriverDetailsView from "./debriefDriver/views/SearchDriverDetailsView";
import ReceiveContainer from "./receiveContainer/ReceiveContainer";
import DriverSummaryView from "./debriefDriver/views/DriverSummaryView/DriverSummaryView";
import PackageOverviewView from "./receivePackage/views/package/PackageOverviewView";
import OverrideCategoryView from "./receivePackage/views/Override/OverrideCategoryView/OverrideCategoryView";
import SelectOverrideStatusView from "./receivePackage/views/Override/SelectStatusView";
import ResolvePackageStatusView from "./receivePackage/views/Override/ResolvePackageStatusView";
import PackageDetailsView from "./receivePackage/views/package/PackageDetailsView";
import ReturnCompleteView from "./debriefDriver/views/ReturnCompleteView";
import BulkTransferView from "./debriefDriver/views/BulkTransferView";
import BulkTransferSuccessView from "./debriefDriver/views/BulkTransferSuccessView";
import ReceivePackageContextProvider from "./receivePackage/context/ReceivePackageContext";
import ScanPackageView from "./receivePackage/views/package/ScanPackageView";
import PackageReturnedView from "./receivePackage/views/package/PackageReturnedView";
import DebriefDriverContextProvider from "./debriefDriver/context/DebriefDriverContext";
import AppContextProvider from "./appContext/context/AppContext";
import TopNavigationBar from "./appContext/views/TopNavigationBar";
import Column from "@amzn/meridian/column";
import RecipientInformationView from "./receivePackage/views/Override/RecipientInformationView";
import {navigate} from "./constants/routes";
import {
    PUMAMetricHelper,
    FeatureManager,
    PlayerHelper,
    NativeMeshInteractor
} from "@amzn/dolphin-web-framework";
import DriverConfirmView from "./debriefDriver/views/DriverConfirmView";
import {getRegion, getStage} from "./utils/Network/BaseURLUtils";
import {Loader, TrainingBlockerView} from "@amzn/dolphin-uiweb-framework";
import {MeshClient} from "@amzn/dolphin-web-framework";
import {PlayerConstants} from "./player/PlayerConstant"
import PlayerContextProvider from "./player/PlayerContext";

function publishRoutePath(location) {
    const metricName = location.pathname;
    PUMAMetricHelper.publishCountToDolphinCSM(metricName);
}

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {loadFeatures: true, trainingBlocker: false};
    }

    renderTrainingBlockerView = () => {
        return <TrainingBlockerView
            title={"training_blocker_title"}
            description={"training_blocker_desc"}
            buttonText={"got_it"}
            buttonOnClick={() => NativeMeshInteractor.exitModule()}/>
    }

    async componentWillMount() {
        await MeshClient.connectWebSocket();
        await FeatureManager.getFeatures(getStage(), getRegion());
        this.setState({loadFeatures: false});
        PlayerHelper.init(PlayerConstants.PROCESS_PATH, PlayerConstants.VERSION, getStage(), getRegion())
            .then(() => {
                PlayerHelper.sendMessage(new window.DolphinPlayer.RTS.ProcessSelectedEvent(), new window.DolphinPlayer.RTS.TaskSelectState());
            });
        publishRoutePath(this.props.location);
        this.onRouteSwitch = this.props.history.listen(() => {
            publishRoutePath(this.props.location);
        });
    }

    async componentWillUnmount() {
        await MeshClient.closeWebSocketConnection();
        PlayerHelper.terminate();
        this.onRouteSwitch();
    }

    render() {
        return (
            <Column spacing="none" height="100%">
                <Switch>
                    <PlayerContextProvider>
                        <AppContextProvider>
                            {this.state.trainingBlocker ? this.renderTrainingBlockerView() :
                                <DebriefDriverContextProvider>
                                    {this.state.loadFeatures ? <Loader/> : null}
                                    <TopNavigationBar/>
                                    <Route exact path={navigate.RECEIVE_CONTAINER} component={ReceiveContainer}/>
                                    <Route exact path={navigate.HOME} component={Homepage}/>
                                    <Route exact path={navigate.SCAN_DRIVER_BY_BADGE_ID} component={DebriefDriverView}/>
                                    <Route exact path={navigate.SEARCH_DRIVER_WITH_KEYWORD}
                                           component={SearchDriverDetailsView}/>
                                    <Route exact path={navigate.DRIVER_CONFIRM} component={DriverConfirmView}/>
                                    <Route exact path={navigate.DRIVER_SUMMARY} component={DriverSummaryView}/>
                                    <Route exact path={navigate.BULK_TRANSFER} component={BulkTransferView}/>
                                    <Route exact path={navigate.BULK_TRANSFER_SUCCESS}
                                           component={BulkTransferSuccessView}/>
                                    <ReceivePackageContextProvider>
                                        <Route exact path={navigate.RECEIVE_PACKAGE} component={ScanPackageView}/>
                                        <Route exact path={navigate.RETURNED_PACKAGE} component={PackageReturnedView}/>
                                        <Route exact path={navigate.PACKAGE_OVERVIEW} component={PackageOverviewView}/>
                                        <Route exact path={navigate.SELECT_OVERRIDE_CATEGORY}
                                               component={OverrideCategoryView}/>
                                        <Route exact path={navigate.SELECT_TARGET_STATUS}
                                               component={SelectOverrideStatusView}/>
                                        <Route exact path={navigate.RECIPIENT_INFORMATION}
                                               component={RecipientInformationView}/>
                                        <Route exact path={navigate.PACKAGE_DETAILS} component={PackageDetailsView}/>
                                    </ReceivePackageContextProvider>
                                    <Route exact path={navigate.RETURN_COMPLETE} component={ReturnCompleteView}/>
                                    <Route exact path={navigate.RESOLVE_PACKAGES} component={ResolvePackageStatusView}/>
                                </DebriefDriverContextProvider>
                            }
                        </AppContextProvider>
                    </PlayerContextProvider>
                </Switch>
            </Column>
        );
    }
}

export default withRouter(App);
