import {actionType} from "../../constants/actionType";
import {receiveContainer} from "../network/ReceiveContainerNetworkAPI";

export const ReceiveContainerReducer = (state, action) => {
    switch (action.type) {
        case actionType.RECEIVE_CONTAINER:
            const {dispatcher, debriefGlobalDispatcher, trackingId} = action.data;
            receiveContainer(dispatcher, debriefGlobalDispatcher, trackingId)
            return {...state, containerReceive: {}, requestCompleted: false};

        case actionType.RECEIVE_CONTAINER_RESPONSE:
            return {...state, isLoading: false, containerReceive: action.data, requestCompleted: true};

        case actionType.LOADING:
            return {...state, isLoading: true, requestCompleted: false};

        case actionType.LOADING_COMPLETE:
            return {...state, isLoading: false};

        case actionType.RESET_REQUEST:
            return {...state, requestCompleted: false}

        case actionType.RESET_STATE:
            return {isLoading: false, requestCompleted: false, containerReceive: {}}

        default:
            return state;
    }
}