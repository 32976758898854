import React, {useContext, useEffect, useState} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {DetailsCardView, HeaderView, Loader} from "@amzn/dolphin-uiweb-framework";
import {ReceivePackageContext} from "../../../context/ReceivePackageContext";
import NotificationView from "../../../../appContext/views/NotificationView";
import {AppContext} from "../../../../appContext/context/AppContext";
import {DebriefDriverContext} from "../../../../debriefDriver/context/DebriefDriverContext";
import {ValidationUtils} from "../../../../utils/Utils";
import {NotificationType} from "../../../../constants/constants";
import {isTargetStatusNotNull} from "./Utils";
import {navigate} from "../../../../constants/routes";
import {ResponseStatus} from "../../../../receiveContainer/view/Constants";
import {FormattedMessage, injectIntl} from "react-intl";
import {interceptBackButton, interceptBackButtonStop} from "../../../../Handler/BackPressHandler";
import {PlayerContext} from "../../../../player/PlayerContext";
import {ApplicationActions} from "../../../../player/ApplicationActions";
import {PlayerConstants, StatusOverrideOption} from "../../../../player/PlayerConstant";

const PackageOverviewView = (props) => {
    const {
        state: {
            packageData: { userOutput: {responseStatus = ""} = {} },
            requestCompleted, isLoading, packageAttributes: contextPackageAttributes = {},
            targetList: {
                targetStatusList: {
                    inStationPkgStatusReasonMapping = {},
                    onRoadPkgStatusReasonMapping = {}
                } = {}
            } = {}
        }, receivePackageActions
    } = useContext(ReceivePackageContext);

    const {
        state: {
            isResolvingPackages, debriefDetails: {packageDetailsMap = {}} = {},
            unresolvedPackagesIndex = 0, unresolvedPackages = []
        }
    } = useContext(DebriefDriverContext);
    const {appActions} = useContext(AppContext);
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);
    const [PACKAGE_DETAILS_SELECTED, UPDATE_TARGET_STATUS_SELECTED] = ["PACKAGE_DETAILS", "UPDATE_TARGET_STATUS"]
    const [optionSelected, setOptionSelected] = useState("")
    const [packageAttributes, setPackageAttributes] = useState({})


    const updateStatus = () => {
        receivePackageActions.getTargetStatus(packageAttributes.scannableId, receivePackageActions, appActions.setNotification)
        setOptionSelected(UPDATE_TARGET_STATUS_SELECTED);
    }

    const cancelButton = () => {
        dispatchPlayer({type: ApplicationActions.STATUS_OVERRIDE, data:{ operation: StatusOverrideOption.CANCEL, view: PlayerConstants.PACKAGE_OVERVIEW}})
        props.history.push(navigate.RETURNED_PACKAGE)
    }

    const packageDetails = () => {
        const data = {
            scannableId: packageAttributes.scannableId,
            shipmentType: packageAttributes.shipmentType
        }
        receivePackageActions.getPackageDetails(data, receivePackageActions, appActions.setNotification)
        setOptionSelected(PACKAGE_DETAILS_SELECTED)
    }

    const onBackPressed = () => {
        appActions.openBackDrop()
    }

    useEffect(() => {
        if(window && window.DolphinPlayer) {
            statePlayer.backEnabled = true
            statePlayer.currentState = new window.DolphinPlayer.RTS.StatusOverrideConfirmState(true, false);
        }
        appActions.setBackPress(onBackPressed)
        interceptBackButton()
        return () => {
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])

    useEffect(() => {
        if (requestCompleted) {
            receivePackageActions.resetRequest()
            switch (optionSelected) {
                case UPDATE_TARGET_STATUS_SELECTED:
                    if (isTargetStatusNotNull(inStationPkgStatusReasonMapping, onRoadPkgStatusReasonMapping)) {
                        dispatchPlayer({type: ApplicationActions.STATUS_OVERRIDE, data:{ operation: StatusOverrideOption.UPDATE, areOptionsAvailable: true}})
                        props.history.push(navigate.SELECT_OVERRIDE_CATEGORY)
                    } else {
                        dispatchPlayer({type: ApplicationActions.STATUS_OVERRIDE, data:{ operation: StatusOverrideOption.UPDATE, areOptionsAvailable: false}})
                        appActions.setNotification(NotificationType.WARNING, props.intl.formatMessage({id: "no_override_option_available"}))
                    }
                    break
                case PACKAGE_DETAILS_SELECTED: {
                    dispatchPlayer({type: ApplicationActions.STATUS_OVERRIDE, data:{ operation: StatusOverrideOption.PACKAGE_DETAILS, isSkipEnabled: isResolvingPackages}})
                    props.history.push(navigate.PACKAGE_DETAILS)
                    break
                }
                default:
                    break
            }
        }
    }, [requestCompleted])

    const isHelpActive = () => {
        return !((isResolvingPackages) || (responseStatus === ResponseStatus.ERROR))
    }

    useEffect(() => {
        if (ValidationUtils.isNotEmptyObject(contextPackageAttributes)) {
            setPackageAttributes(contextPackageAttributes)
        }
    }, [contextPackageAttributes])

    useEffect(() => {
        if (isResolvingPackages) {
            const scannableId = unresolvedPackages[unresolvedPackagesIndex]
            if (scannableId) {
                receivePackageActions.setPackageAttributes(packageDetailsMap[scannableId])
            }
        }
    }, [])

    const displayDefaultComponent = () => {
        return (
            <Column height="100%" heights="fill" spacingInset="medium">
                <Column alignmentHorizontal="center">
                    <NotificationView/>
                    <HeaderView primaryTitle={{title: packageAttributes.packageState}}
                                secondaryTitle={{title: props.intl.formatMessage({id:"update_status_message"}), type: "Bold", size: "Small"}}/>
                    <Column width="100%">
                        <DetailsCardView snapshots={[{title: packageAttributes.scannableId, description: props.intl.formatMessage({id:"package_id"})}]}
                                         headerFontSize="Large"/>
                    </Column>
                </Column>
                <Column alignmentHorizontal="center" alignmentVertical="bottom">
                    <Row width="100%" widths="fill" alignmentVertical="bottom">
                        <Button size="large" onClick={updateStatus} type="primary">
                            <FormattedMessage id={"btn_update_status"} />
                        </Button>
                    </Row>
                    {isHelpActive() ?
                        <Row width="100%" widths="fill" alignmentVertical="bottom">
                            <Button size="large" onClick={cancelButton} type="secondary">
                                <FormattedMessage id={"btn_cancel"} />
                            </Button>
                        </Row>:
                        <Row width="100%" widths="fill" alignmentVertical="bottom">
                            <Button size="large" onClick={packageDetails} type="secondary">
                                <FormattedMessage id={"btn_view_package_details"} />
                            </Button>
                        </Row>
                    }
                </Column>
            </Column>
        )
    }


    return (
        <Column height="100%" heights="fill">
            {isLoading && <Loader/>}
            {displayDefaultComponent()}
        </Column>
    )
}

export default injectIntl(PackageOverviewView);
