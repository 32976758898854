import React, {useContext, useEffect, useState} from "react"
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {ReceivePackageContext} from "../../../context/ReceivePackageContext";
import {ValidationUtils} from "../../../../utils/Utils";
import {getInStationOverride, getOnRoadOverride} from "./Constants";
import {injectIntl} from "react-intl";
import {PlayerContext} from "../../../../player/PlayerContext";

const OverrideCategoryView = (props) => {
    const {
        state: {
            targetList: {
                targetStatusList, targetStatusList: {
                    inStationPkgStatusReasonMapping = {},
                    onRoadPkgStatusReasonMapping = {}
                } = {}
            } = {}
        }, receivePackageActions
    } = useContext(ReceivePackageContext);

    const {dispatchPlayer} = useContext(PlayerContext);

    let tasks = []

    const [initalState, setInitialState] = useState({pageTitle: props.intl.formatMessage({id: "select_override_status_message"}), tasks: []})

    useEffect(() => {
        if (ValidationUtils.isNotEmptyObject(inStationPkgStatusReasonMapping)) {
            tasks.push(getInStationOverride(props, receivePackageActions.setTargetCategory, dispatchPlayer))
        }
        if (ValidationUtils.isNotEmptyObject(onRoadPkgStatusReasonMapping)) {
            tasks.push(getOnRoadOverride(props, receivePackageActions.setTargetCategory, dispatchPlayer))
        }
        setInitialState({
            ...initalState,
            tasks: tasks
        })
    }, [targetStatusList])

    return (
        <TaskView {...initalState} />
    )
}

export default injectIntl(OverrideCategoryView)
