export const actionType = {
    //Receive Package Actions

    /**
     * network request actions
     */
    RECEIVE_PACKAGE: "RECEIVE_PACKAGE",
    GET_TARGET_STATUS_LIST: "GET_TARGET_STATUS_LIST",
    UPDATE_TARGET_STATUS: "UPDATE_TARGET_STATUS",
    GET_PACKAGE_DETAILS: "GET_PACKAGE_DETAILS",
    RESET_PACKAGE_DETAILS: "RESET_PACKAGE_DETAILS",


    /**
     * network response actions
     */
    RECEIVE_PACKAGE_RESPONSE: "RECEIVE_PACKAGE_RESPONSE",
    RECEIVED_TARGET_STATUS_LIST: "RECEIVED_TARGET_STATUS_LIST",
    UPDATE_TARGET_STATUS_RESPONSE: "UPDATE_TARGET_STATUS_RESPONSE",
    GET_PACKAGE_DETAILS_RESPONSE: "GET_PACKAGE_DETAILS_RESPONSE",

    /**
     * state based actions
     */
    UPDATE_STATUS_DATA: "UPDATE_STATUS_DATA",

    //Debrief Driver Actions

    /**
     * network request actions
     */
    GET_DRIVER_BY_BADGE_ID: "GET_DRIVER_BY_BADGE_ID",
    SEARCH_DRIVER: "SEARCH_DRIVER",
    GET_DEBRIEF_DETAILS: "GET_DEBRIEF_DETAILS",
    BULK_RECEIVE_PACKAGE: "BULK_RECEIVE_PACKAGE",

    /**
     * network response actions
     */
    GET_DRIVER_BY_BADGE_ID_RESPONSE: "GET_DRIVER_BY_BADGE_ID_RESPONSE",
    SEARCH_DRIVER_RESPONSE: "SEARCH_DRIVER_RESPONSE",
    GET_DEBRIEF_DETAILS_RESPONSE: "GET_DEBRIEF_DETAILS_RESPONSE",
    BULK_RECEIVE_PACKAGE_RESPONSE: "BULK_RECEIVE_PACKAGE_RESPONSE",

    /**
     * state based actions
     */
    SET_UNRESOLVED_PACKAGE: "SET_UNRESOLVED_PACKAGE",
    SET_DEBRIEF_COMPLETE: "SET_DEBRIEF_COMPLETE",
    SET_RESOLVING_PACKAGES: "SET_RESOLVING_PACKAGES",
    RESET_RESOLVING_PACKAGES: "RESET_RESOLVING_PACKAGES",
    SET_PACKAGE_ATTRIBUTES: "SET_PACKAGE_ATTRIBUTES",
    SET_CATEGORY: "SET_CATEGORY",
    SET_TRANSPORTER: "SET_TRANSPORTER",
    SET_DRIVER_SUMMARY: "SET_DRIVER_SUMMARY",
    NEXT_PACKAGE: "NEXT_PACKAGE",
    ACTIVATE_DRIVER_DEBRIEF_WORKFLOW: "ACTIVATE_DRIVER_DEBRIEF_WORKFLOW",
    DISPLAY_PROGRESS_BAR: "DISPLAY_PROGRESS_BAR",
    SET_DRIVER_PROGRESS: "SET_DRIVER_PROGRESS",

    //Receive Container Actions
    RECEIVE_CONTAINER: "RECEIVE_CONTAINER",
    RECEIVE_CONTAINER_RESPONSE: "RECEIVE_CONTAINER_RESPONSE",

    //App Actions
    SET_NOTIFICATION: "SET_NOTIFICATION",
    RESET_NOTIFICATION: "RESET_NOTIFICATION",
    ENABLE_HELP: "ENABLE_HELP",
    DISABLE_HELP: "DISABLE_HELP",
    SET_HELP_OPTIONS: "SET_HELP_OPTIONS",
    RESET_HELP_OPTIONS: "RESET_HELP_OPTIONS",
    SET_NAV_THEME: "SET_NAV_THEME",
    OPEN_BACK_DROP: "OPEN_BACK_DROP",
    CLOSE_BACK_DROP: "CLOSE_BACK_DROP",
    SET_BACK_PRESS: "SET_BACK_PRESS",

    //globally used actions
    LOADING: "LOADING",
    LOADING_COMPLETE: "LOADING_COMPLETE",
    RESET_REQUEST: "RESET_REQUEST",
    RESET_STATE: "RESET_STATE",

    //testing
    OTHER: "OTHER"
};
