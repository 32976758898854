import React from 'react';
import {ErrorView} from "@amzn/dolphin-uiweb-framework";
import {Logger, NativeMeshInteractor, PUMAMetricHelper} from "@amzn/dolphin-web-framework";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        Logger.log.fatal("ErrorBoundary", error, errorInfo);
        const metricName = "clientError";
        PUMAMetricHelper.publishCountToDolphinCSM(metricName);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorView errorMessage={"debrief_service_error"} onBackClicked={NativeMeshInteractor.exitModule}/>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;