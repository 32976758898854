export const driftEndpoints = {
    RECEIVE_PACKAGE: "receivePackage",
    RECEIVE_CONTAINER: "container/receive",
    GET_TARGET_STATUS_LIST: "getTargetStatusList",
    UPDATE_TARGET_STATUS: "updateShipmentsStatus",
    GET_PACKAGE_DETAILS: "getPackageDetails",
    SEARCH_TRANSPORTER_BY_KEYWORD: "searchTransporter",
    GET_TRANSPORTER_BY_BADGE_ID: "getTransporterByBadgeId",
    GET_DEBRIEF_DETAILS: "getDebriefDetails",
    BULK_RECEIVE_PACKAGE: "bulkReceivePackage",

    //test endpoint
    TEST: "TEST"
}
export const DartEndpoints = {
    RECEIVE_PACKAGE: "shipments/return"
}