import React, {createContext, useReducer, useContext} from "react";
import {ReceivePackageReducer} from "../reducers/ReceivePackageReducer";
import {actionType} from "../../constants/actionType";
import {PlayerContext} from "../../player/PlayerContext";
import {ApplicationActions} from "../../player/ApplicationActions";

export const ReceivePackageContext = createContext();

const ReceivePackageContextProvider = (props) => {
    const initState = {
        isLoading: false,
        packageData: {},
        targetList: {},
        updateStatusData: null,
        requestCompleted: false,
        updateSuccess: false,
        packageDetails: {},
        overrideCategory: null,
        packageAttributes: {}
    };
    const [state, dispatch] = useReducer(ReceivePackageReducer, initState);
    const {dispatchPlayer} = useContext(PlayerContext);

    const receivePackageActions = {
        receivePackage: (trackingId, transporterId, dispatcher, debriefGlobalDispatcher) => {
            dispatch({
                type: actionType.RECEIVE_PACKAGE,
                data: {
                    trackingId: trackingId,
                    transporterId: transporterId,
                    dispatcher: dispatcher,
                    debriefGlobalDispatcher: debriefGlobalDispatcher
                }
            })
        },
        receivePackageResponse: (responseData) => {
            dispatchPlayer({type: ApplicationActions.PACKAGE_SCANNED, data: responseData})
            dispatch({type: actionType.RECEIVE_PACKAGE_RESPONSE, data: responseData})
        },
        setPackageAttributes: (packageAttributes) => {
            dispatch({type: actionType.SET_PACKAGE_ATTRIBUTES, data: packageAttributes})
        },
        getTargetStatus: (trackingId, dispatcher, debriefGlobalDispatcher) => {
            dispatch({
                type: actionType.GET_TARGET_STATUS_LIST,
                data: {
                    trackingId: trackingId,
                    dispatcher: dispatcher,
                    debriefGlobalDispatcher: debriefGlobalDispatcher
                }
            })
        },
        getTargetStatusResponse: (responseData) => {
            dispatch({type: actionType.RECEIVED_TARGET_STATUS_LIST, data: responseData})
        },
        setTargetCategory: (targetCategory) => {
            dispatch({type: actionType.SET_CATEGORY, data: targetCategory})
        },
        updateStatusData: (status) => {
            dispatch({type: actionType.UPDATE_STATUS_DATA, data: status})
        },
        updateTargetStatus: (dispatcher, debriefGlobalDispatcher) => {
            dispatch({
                type: actionType.UPDATE_TARGET_STATUS,
                data: {
                    dispatcher: dispatcher,
                    debriefGlobalDispatcher: debriefGlobalDispatcher
                }
            })
        },
        updateTargetStatusResponse: (responseData) => {
            dispatch({type: actionType.UPDATE_TARGET_STATUS_RESPONSE, data: responseData})
        },
        getPackageDetails: (data, dispatcher, debriefGlobalDispatcher) => {
            dispatch({
                type: actionType.GET_PACKAGE_DETAILS,
                data: {
                    data: data,
                    dispatcher: dispatcher,
                    debriefGlobalDispatcher: debriefGlobalDispatcher
                }
            })
        },
        getPackageDetailsResponse: (responseData) => {
            dispatch({type: actionType.GET_PACKAGE_DETAILS_RESPONSE, data: responseData})
        },
        resetPackageDetails: () => {
            dispatch({type: actionType.RESET_PACKAGE_DETAILS})
        },
        isLoading: () => {
            dispatch({type: actionType.LOADING})
        },
        loadingComplete: () => {
            dispatch({type: actionType.LOADING_COMPLETE})
        },
        resetRequest: () => {
            dispatch({type: actionType.RESET_REQUEST})
        },
        resetState: () => {
            dispatch({type: actionType.RESET_STATE})
        }
    }

    return (
        <ReceivePackageContext.Provider value={{state, receivePackageActions}}>
            {props.children}
        </ReceivePackageContext.Provider>
    )
}

export default ReceivePackageContextProvider;
