import React, {useContext, useEffect} from 'react';
import Column from "@amzn/meridian/column";
import {ReceiveContainerContext} from "../context/ReceiveContainerContext";
import {Loader, ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import NotificationView from "../../appContext/views/NotificationView";
import {AppContext} from "../../appContext/context/AppContext";
import {resolveNotificationUtil} from "./Utils";
import {ReceiveContainerInit} from "./Constants";
import {injectIntl} from "react-intl";
import {interceptBackButton, interceptBackButtonStop} from "../../Handler/BackPressHandler";

const ReceiveContainerView = (props) => {
    const {
        state:
            {
                isLoading, requestCompleted,
                containerReceive: {
                    scannableId = "",
                    userOutput: {
                        responseStatus = "", responseCode = "",
                        displayOutput: {
                            containerAttributes:
                                {
                                    containerType = "", sourceName = ""
                                } = {}
                        } = {}
                    } = {}
                } = {}
            },
        receiveContainerActions
    } = useContext(ReceiveContainerContext);
    const {appActions} = useContext(AppContext);

    const onPackageScan = (trackingId) => {
        receiveContainerActions.fetchContainer(trackingId, receiveContainerActions, appActions.setNotification)
    }

    useEffect(() => {
        if (requestCompleted) {
            receiveContainerActions.resetRequest()
            const {notificationType, message} = resolveNotificationUtil(props.intl, scannableId,
                responseCode, responseStatus, containerType, sourceName)
            appActions.setNotification(notificationType, message)
        }
    }, [requestCompleted]);


    const onBackPressed = () => {
        appActions.openBackDrop()
    }

    useEffect(() => {
        appActions.setBackPress(onBackPressed)
        interceptBackButton();
        return () => {
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])


    return (
            <Column height="100%" spacingInset="medium">
                {isLoading && <Loader/>}
                <NotificationView/>
                <ScanWithCodeView
                    {...ReceiveContainerInit}
                    focusLock={true}
                    onSubmit={onPackageScan}/>
            </Column>
    )
}

export default injectIntl(ReceiveContainerView);