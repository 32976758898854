import {CATEGORY_INSTATION} from "../../../../constants/constants";
import {ReconciliationStatus} from "../../../../debriefDriver/views/DriverSummaryView/Constants";
import {addPackageToSummary} from "../../package/ScanPackageView/Utils";

const STATUS_DELIVERED = "DELIVERED"
const REASON_CODE_DELIVERED_TO_NEIGHBOR = "DELIVERED_TO_NEIGHBOR"

export const deliveredToNeighbour = (targetStatus, targetReason) => {
    return Boolean((targetStatus && targetReason) && (targetStatus === STATUS_DELIVERED)
        && (targetReason === REASON_CODE_DELIVERED_TO_NEIGHBOR))
}

export const resolveStatusAndAddToSummary = (trackingId, driverSummary, targetStatus, selectedCategory, isResolvingPackages) => {
    let finalStatus;
    if (STATUS_DELIVERED === targetStatus) {
        if (isResolvingPackages) {
            finalStatus = ReconciliationStatus.DELIVERED_BY_RESOLVING
        } else {
            finalStatus = ReconciliationStatus.DELIVERED
        }
    } else if (CATEGORY_INSTATION === selectedCategory) {
        finalStatus = ReconciliationStatus.RETURNED
    } else {
        finalStatus = ReconciliationStatus.RESOLVED
    }
    return addPackageToSummary(trackingId, driverSummary, finalStatus)
}


