export const METRICS = {
    GET_TRANSPORTER_BY_BADGE_ID: "get_transporter_by_badge_id",
    SEARCH_TRANSPORTER_BY_KEYWORD: "search_transporter_by_keyword",
    GET_DEBRIEF_DETAILS: "get_debrief_details",
    BULK_RECEIVE_PACKAGE: "bulk_receive_package",

    RECEIVE_CONTAINER: "receive_container",

    RECEIVE_PACKAGE: "receive_package",
    GET_TARGET_STATUS_LIST: "get_target_status_list",
    UPDATE_TARGET_STATUS: "update_target_status",
    GET_PACKAGE_DETAILS: "get_package_details"
}