import {ValidationUtils} from "../../../utils/Utils";
import {ReconciliationStatus, UnresolvedPackageStatus} from "./Constants";

export const reconciliationStatusToTrackingIdMapUtil = (buckets) => {
    let reconciliationStatusToTrackingIdMap = {}
    if (ValidationUtils.isNotEmptyObject(buckets)) {
        Object.keys(ReconciliationStatus).forEach((reconStatus) => {
            reconciliationStatusToTrackingIdMap[reconStatus] = []
        })
        buckets.forEach((bucket) =>
            reconciliationStatusToTrackingIdMap[ReconciliationStatus[bucket.id]] = bucket.scannableIdList
        )
    }
    return reconciliationStatusToTrackingIdMap
}


export const getUnresolvedPackages = (driverSummary) => {
    let unresolvedPackagesList = []
    if (ValidationUtils.isNotEmptyObject(driverSummary)) {
        UnresolvedPackageStatus.forEach((status) => {
                if(status in driverSummary) {
                    unresolvedPackagesList.push(...driverSummary[status])
                }
            }
        )
    }
    return unresolvedPackagesList
}


export const driverReturnProgress = (driverSummary) => {
    let expectedReturn = 0;
    let returned = 0;
    if(ValidationUtils.isNotEmptyObject(driverSummary)) {
        expectedReturn += driverSummary[ReconciliationStatus.OUT_ON_ROAD].length
        expectedReturn += driverSummary[ReconciliationStatus.RETURNING_TO_STATION].length
        expectedReturn += driverSummary[ReconciliationStatus.MISSING].length

        returned += driverSummary[ReconciliationStatus.RETURNED].length
        returned += driverSummary[ReconciliationStatus.RESOLVED].length
        returned += driverSummary[ReconciliationStatus.DELIVERED_BY_RESOLVING].length
        returned += driverSummary[ReconciliationStatus.SKIPPED].length
        returned += driverSummary[ReconciliationStatus.MISSING_SKIPPED].length
    }
    expectedReturn += returned
    return {
        current: returned,
        total: expectedReturn
    }
}
