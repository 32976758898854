import {ValidationUtils} from "../../../../utils/Utils";
import {ResponseCode} from "./Constants";

export const getErrorMessage = (intl, trackingId, responseCode, tagList) => {
    let message = intl.formatMessage({id: "return_error_message"}, {
        trackingId: trackingId,
    })
    if (tagList && tagList.length > 0) {
        if (tagList[0].shipmentTag) {
            message = intl.formatMessage({id: "status_tagged_message"}, {
                trackingId: trackingId,
                tag: tagList[0].shipmentTag
            })
        }
    }

    switch (responseCode) {
        case ResponseCode.INVALID_TRACKING_ID:
            message = intl.formatMessage({id: "invalid_trackingId_message"})
            break;
        case ResponseCode.WRONG_STATION:
            message = intl.formatMessage({id: "wrong_station_error_message"})
            break;
        case ResponseCode.RETURN_TO_SELLER:
            message = intl.formatMessage({id: "return_to_seller"})
            break;
        default:
            break
    }
    return message
}


export const updateDriverSummaryWithPackageStatus = (reconciliationStatusToTrackingIdMap, trackingId, reconciliationStatus) => {
    if (ValidationUtils.isNotEmptyObject(reconciliationStatusToTrackingIdMap)) {
        if (reconciliationStatus in reconciliationStatusToTrackingIdMap) {
            reconciliationStatusToTrackingIdMap[reconciliationStatus].push(trackingId)
        } else {
            reconciliationStatusToTrackingIdMap[reconciliationStatus] = [trackingId]
        }
    }
    return reconciliationStatusToTrackingIdMap
}

export const addPackageToSummary = (trackingId, reconciliationStatusToTrackingIdMap, reconcilationStatus) => {
    if(ValidationUtils.isNotEmptyObject(reconciliationStatusToTrackingIdMap)) {
        Object.keys(reconciliationStatusToTrackingIdMap)
            .forEach((value) => {
                reconciliationStatusToTrackingIdMap[value] = reconciliationStatusToTrackingIdMap[value].filter(entity => entity !== trackingId)
            })
        return updateDriverSummaryWithPackageStatus(
            reconciliationStatusToTrackingIdMap, trackingId, reconcilationStatus)
    }
    return {}
}
