import {CATEGORY_INSTATION, CATEGORY_ONROAD} from "../../../../constants/constants";
import {navigate} from "../../../../constants/routes";
import {ApplicationActions} from "../../../../player/ApplicationActions";


export const getInStationOverride = (props, setCategory, dispatchPlayer) => {

    const setInStationCategory = () => {
        setCategory(CATEGORY_INSTATION)
        dispatchPlayer({type: ApplicationActions.OVERRIDE_TYPE_SELECT_STATION});
        props.history.push(navigate.SELECT_TARGET_STATUS)
    }
    return ({
        title: props.intl.formatMessage({id: "btn_in_station_status_override"}),
        description: props.intl.formatMessage({id: "in_station_override_message"}),
        onClick: setInStationCategory
    })
}

export const getOnRoadOverride = (props, setCategory, dispatchPlayer) => {
    const setOnRoadCategory = () => {
        setCategory(CATEGORY_ONROAD)
        dispatchPlayer({type: ApplicationActions.OVERRIDE_TYPE_SELECT_ON_ROAD});
        props.history.push(navigate.SELECT_TARGET_STATUS)
    }
    return ({
        title: props.intl.formatMessage({id: "btn_on_road_status_override"}),
        description: props.intl.formatMessage({id: "on_road_override_message"}),
        onClick: setOnRoadCategory
    })
}
