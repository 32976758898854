export const PlayerConstants = {
    PROCESS_PATH: "RTS_Receive",
    VERSION: "V1.0.147.0",
    DEBRIEF_PACKAGES: "DEBRIEF_PACKAGES",
    DEBRIEF_DRIVER: "DEBRIEF_DRIVER",
    PACKAGE_OVERVIEW: "PACKAGE_OVERVIEW",
    SELECT_STATUS: "SELECT_STATUS",
    CONFIRM_DRIVER: "CONFIRM_DRIVER",
    SELECT_ANOTHER_DRIVER: "SELECT_ANOTHER_DRIVER"
};

export const HelpOption = {
    OVERRIDE_PACKAGE_STATUS: "OVERRIDE_PACKAGE_STATUS",
    VIEW_PACKAGE_DETAILS: "VIEW_PACKAGE_DETAILS",
    MANUAL_SCAN: "MANUAL_SCAN"
}

export const BackOption = {
    CLOSE: "CLOSE",
    EXIT: "EXIT",
    CONTINUE: "CONTINUE"
}

export const StatusOverrideOption = {
    CANCEL: "CANCEL",
    UPDATE: "UPDATE",
    PACKAGE_DETAILS: "PACKAGE_DETAILS"
}

export const BulkTransferOption = {
    DEBRIEF_DRIVER: "DEBRIEF_DRIVER",
    CONTINUE_RECEIVING: "CONTINUE_RECEIVING"
}

export const PackageState = {
    REPROCESS: "Reprocess",
    FC_SC_RETURN: "FC/SC Return",
    INDUCTED: "INDUCTED",
    STATION_RETURN: "Station Return",
    PROBLEM_SOLVE: "Problem Solve",
    CUSTOMER_REQUEST: "Customer Request"
}

export const mapPackageState = (responseCode) => {
    switch (responseCode) {
        case PackageState.REPROCESS:
            return window.DolphinPlayer.RTS.PackageScanPackageState.REPROCESS;
        case PackageState.FC_SC_RETURN:
            return window.DolphinPlayer.RTS.PackageScanPackageState.BACK_TO_FC;
        case PackageState.INDUCTED:
            return window.DolphinPlayer.RTS.PackageScanPackageState.INDUCTED;
        case PackageState.STATION_RETURN:
            return window.DolphinPlayer.RTS.PackageScanPackageState.BACK_TO_STATION;
        case PackageState.PROBLEM_SOLVE:
            return window.DolphinPlayer.RTS.PackageScanPackageState.PROBLEM_SOLVE;
        case PackageState.CUSTOMER_REQUEST:
            return window.DolphinPlayer.RTS.PackageScanPackageState.CUSTOMER_REQUEST;
        default:
            return null;
    }
}
