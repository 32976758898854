import React, {useContext, useEffect, useState} from "react";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {DetailsCardView} from "@amzn/dolphin-uiweb-framework";
import {DebriefDriverContext} from "../../context/DebriefDriverContext";
import {createDriverSummarySnapshot, createDriverSummarySnapshotAfterDebrief} from "./Constants";
import {AppContext} from "../../../appContext/context/AppContext";
import {ValidationUtils} from "../../../utils/Utils";
import {ReconciliationStatus} from "./Constants";
import {navigate} from "../../../constants/routes";
import {FormattedMessage} from "react-intl";
import {interceptBackButton, interceptBackButtonStop} from "../../../Handler/BackPressHandler";
import {PlayerContext} from "../../../player/PlayerContext";
import {ApplicationActions} from "../../../player/ApplicationActions";

const DriverSummaryView = (props) => {
    const {
        state: {
            driverSummary = {},
            driverSummary: {[ReconciliationStatus.BULK_RETURNING_TO_STATION]: bulkTransferPackageList = []} = {},
            isDebriefComplete
        }, debriefDriverActions
    } = useContext(DebriefDriverContext)
    const {appActions} = useContext(AppContext);
    const {dispatchPlayer} = useContext(PlayerContext)
    const [data, setData] = useState([]) // fetch data from debrief api

    const handleNext = () => {
        if (bulkTransferPackageList.length > 0) {
            dispatchPlayer({type: ApplicationActions.RETURN_OVERVIEW_ACKNOWLEDGE, data:{bulkPackages: true}})
            props.history.push(navigate.BULK_TRANSFER);
        } else {
            dispatchPlayer({type: ApplicationActions.RETURN_OVERVIEW_ACKNOWLEDGE, data:{bulkPackages: false}})
            debriefDriverActions.displayProgressBar()
            props.history.push(navigate.RECEIVE_PACKAGE);
        }
    }

    const debriefAnotherDriver = () => {
        dispatchPlayer({type: ApplicationActions.RETURN_SUMMARY, data: navigate.SCAN_DRIVER_BY_BADGE_ID});
        debriefDriverActions.resetDebriefDriverContext()
        appActions.resetNotification()
        props.history.push(navigate.SCAN_DRIVER_BY_BADGE_ID);
    }

    const exit = () => {
        dispatchPlayer({type: ApplicationActions.RETURN_SUMMARY, data: navigate.HOME});
        appActions.resetNotification()
        props.history.push(navigate.HOME);
    }

    useEffect(() => {
        if (ValidationUtils.isNotEmptyObject(driverSummary)) {
            let snapshots
            if (isDebriefComplete) {
                snapshots = createDriverSummarySnapshotAfterDebrief(driverSummary)
            } else {
                snapshots = createDriverSummarySnapshot(driverSummary)
            }
            setData(snapshots)
        }
    }, [driverSummary])

    const onBackPressed = () => {
        appActions.openBackDrop()
    }

    useEffect(() => {
        appActions.setBackPress(onBackPressed)
        interceptBackButton();
        return () => {
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])

    const displayOnDebriefComplete = () => {
        return (
            <Column width="100%">
                <Row width="100%" widths="fill" alignmentVertical="bottom">
                    <Button size="large" onClick={debriefAnotherDriver} type="primary">
                        <FormattedMessage id={"reconcile_another_driver"}/>
                    </Button>
                </Row>
                <Row width="100%" widths="fill" alignmentVertical="bottom">
                    <Button size="large" onClick={exit} type="secondary">
                        <FormattedMessage id={"exit"}/>
                    </Button>
                </Row>
            </Column>
        )
    }
    const displayOnDebriefPending = () => {
        return (
            <Row width="100%" widths="fill" alignmentVertical="bottom">
                <Button size="large" onClick={handleNext} type="primary">
                    <FormattedMessage id={"start_returning"}/>
                </Button>
            </Row>
        )
    }

    return (
        <Column height="100%" heights="fill">
            <Column spacingInset="medium">
                <Text alignment="center" type="h300">
                    {isDebriefComplete ?
                        <FormattedMessage id={"return_summary"}/> :
                        <FormattedMessage id={"return_overview"}/>
                    }
                </Text>
                <DetailsCardView snapshots={data}/>
            </Column>
            <Column alignmentHorizontal="center" alignmentVertical="bottom" spacingInset="medium">
                {isDebriefComplete ? displayOnDebriefComplete() : displayOnDebriefPending()}
            </Column>
        </Column>
    )
}

export default DriverSummaryView;
