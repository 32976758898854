import React, {useContext, useEffect} from "react"
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {DebriefDriverContext} from "../context/DebriefDriverContext";
import image from '../../../images/ic_checkmark.png'
import {ReconciliationStatus} from "./DriverSummaryView/Constants";
import {navigate} from "../../constants/routes";
import {FormattedMessage} from "react-intl";
import {AppContext} from "../../appContext/context/AppContext";
import {interceptBackButton, interceptBackButtonStop} from "../../Handler/BackPressHandler";
import {PlayerContext} from "../../player/PlayerContext";
import {ApplicationActions} from "../../player/ApplicationActions";
import {BulkTransferOption} from "../../player/PlayerConstant";

const BulkTransferSuccessView = (props) => {

    const {appActions} = useContext(AppContext)
    const {dispatchPlayer} = useContext(PlayerContext)
    const {state: {driverSummary: {[ReconciliationStatus.BULK_RETURNING_TO_STATION]: packages = []} = {}}, debriefDriverActions} = useContext(DebriefDriverContext)
    const bulkReceivedPackages = packages.length

    const receivePackage = () => {
        debriefDriverActions.displayProgressBar()
        dispatchPlayer({type: ApplicationActions.BULK_TRANSFER_ACKNOWLEDGE, data:{operation: BulkTransferOption.CONTINUE_RECEIVING}});
        props.history.push(navigate.RECEIVE_PACKAGE);
    }

    const debriefAnotherDriver = () => {
        debriefDriverActions.resetDebriefDriverContext()
        dispatchPlayer({type: ApplicationActions.BULK_TRANSFER_ACKNOWLEDGE, data:{operation: BulkTransferOption.DEBRIEF_DRIVER}});
        props.history.push(navigate.SCAN_DRIVER_BY_BADGE_ID);
    }

    const onBackPressed = () => {
        appActions.openBackDrop()
    }

    useEffect(() => {
        appActions.setBackPress(onBackPressed)
        interceptBackButton();
        return () => {
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])

    return (
        <Column height="100%" heights="fill" spacingInset="medium">
            <Column alignmentHorizontal="center" spacingInset="small">
                <img src={image} alt="Successful debrief" style={{width: '20%', height: "auto"}}/>
                <Text type="h200"><FormattedMessage id={"bulk_package_received"} values={{
                    totalPackages:bulkReceivedPackages
                }}/></Text>
                <Text type="h100"><FormattedMessage id={"process_remaining_packages"} /></Text>
            </Column>
            <Column alignmentHorizontal="center" alignmentVertical="bottom">
                <Row width="100%" widths="fill" alignmentVertical="bottom">
                    <Button onClick={debriefAnotherDriver} size="large" type="secondary"><FormattedMessage id={"reconcile_another_driver"}/></Button>
                </Row>
                <Row width="100%" widths="fill" alignmentVertical="bottom">
                    <Button onClick={receivePackage} size="large" type="primary"><FormattedMessage id={"btn_continue_receive"} /></Button>
                </Row>
            </Column>
        </Column>
    )
}

export default BulkTransferSuccessView;
