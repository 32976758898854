import React, {useContext, useEffect, useState} from 'react';
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import Divider from "@amzn/meridian/divider";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import SearchField, {SearchSuggestion, SearchSuggestionHeader} from "@amzn/meridian/search-field";
import {DebriefDriverContext} from "../context/DebriefDriverContext";
import {AppContext} from "../../appContext/context/AppContext";
import NotificationView from "../../appContext/views/NotificationView";
import {reconciliationStatusToTrackingIdMapUtil} from "./DriverSummaryView/Utils";
import {navigate} from "../../constants/routes";
import {FormattedMessage} from "react-intl";
import {interceptBackButton, interceptBackButtonStop} from "../../Handler/BackPressHandler";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import {PlayerContext} from "../../player/PlayerContext";
import {ApplicationActions} from "../../player/ApplicationActions";

const SearchDriverDetailsView = (props) => {
    const {
        state: {
            debriefDetails: {onRoadBuckets} = {},
            isLoading, searchTransporterList = [],
            requestCompleted, transporter = {}
        }, debriefDriverActions
    } = useContext(DebriefDriverContext)
    const {appActions} = useContext(AppContext);
    const {dispatchPlayer} = useContext(PlayerContext);

    const [searchedTransporters, setSearchedTransporters] = useState([]);
    const [keyword, setKeyword] = useState("")
    const [displayProfile, setDisplayProfile] = useState(null)
    const [confirmDriverButtonState, setConfirmDriverButtonState] = useState({
        disabled: true
    });

    const confirmDriver = () => {
        dispatchPlayer({type: ApplicationActions.DRIVER_SELECT_ACKNOWLEDGE})
        debriefDriverActions.getDebriefDetails(debriefDriverActions, appActions.setNotification)
    }

    const keywordChange = (key) => {
        setKeyword(key)
        if (key.length > 2) {
            // dispatch action and getTransportersList
            debriefDriverActions.searchDriver(key, debriefDriverActions, appActions.setNotification)
        } else {
            setSearchedTransporters([]);
        }
    }

    const onClickSuggestion = (data) => {
        const [, name, email] = data[1].split(" | ")
        setDisplayProfile(
            <Box type="outline" spacingInset="medium">
                <Text type="h100">{name}</Text>
                <Text type="b100">{email}</Text>
            </Box>
        )

        searchTransporterList
            .filter(data => data.email === email)
            .forEach(data => debriefDriverActions.setTransporter(data))

        setConfirmDriverButtonState({
            disabled: false
        })
        dispatchPlayer({type: ApplicationActions.DRIVER_SEARCH})
    }

    const onBackPressed = () => {
        appActions.openBackDrop()
    }

    useEffect(() => {
        dispatchPlayer({type: ApplicationActions.ENTER_DRIVER_DETAILS});
        appActions.setBackPress(onBackPressed)
        interceptBackButton();
        return () => {
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])

    useEffect(() => {
        if (searchTransporterList.length) {
            setSearchedTransporters(searchTransporterList
                .filter(data => data.email && data.name)
                .map(data => {
                    let displayData = data.providerCompanyType + " | " + data.name + " | " + data.email;
                    return <SearchSuggestion value={data.transporterId} key={data.transporterId}
                                             onClick={onClickSuggestion}> {displayData} </SearchSuggestion>
                }))
        }
    }, [searchTransporterList])

    useEffect(() => {
        if (transporter && requestCompleted) {
            debriefDriverActions.resetRequest()
            debriefDriverActions.setDriverSummary(reconciliationStatusToTrackingIdMapUtil(onRoadBuckets))
            props.history.push(navigate.DRIVER_SUMMARY);
        }
    }, [requestCompleted]);

    const regularDisplayComponent = () => {
        return (
            <Column height="100%" heights="fill" alignmentVertical="bottom">
                <Column spacingInset="medium" alignmentHorizontal="center">
                    <NotificationView/>
                    <div align="center">
                        <Text type="h300"><FormattedMessage id="enter_details" /></Text>
                        <Text type="b300"><FormattedMessage id="select_the_returning_driver" /></Text>
                        <Box spacingInset="small">
                            <Divider size="small"/>
                        </Box>
                    </div>
                    <Column width="100%">
                        <SearchField value={keyword} onChange={keywordChange} autoFocus={true} placeholder="Search here"
                                     onSubmit={() => {
                                     }}>
                            <SearchSuggestionHeader>
                            </SearchSuggestionHeader>
                            {searchedTransporters}
                        </SearchField>
                        {displayProfile}
                    </Column>
                </Column>
                <Column alignmentHorizontal="center" alignmentVertical="bottom">
                    <Row width="100%" widths="fill" spacingInset="medium" alignmentVertical="bottom">
                        <Button size="large" onClick={confirmDriver} {...confirmDriverButtonState}>
                            <FormattedMessage id="confirm_driver" />
                        </Button>
                    </Row>
                </Column>
            </Column>
        )
    }

    return (
        <Column height="100%" heights="fill">
            {isLoading && <Loader/>}
            {regularDisplayComponent()}
        </Column>
    );
}

export default SearchDriverDetailsView;
