import {ReconciliationStatus} from "../../../../debriefDriver/views/DriverSummaryView/Constants";
import {addPackageToSummary} from "../ScanPackageView/Utils";
import {ValidationUtils} from "../../../../utils/Utils";
import {Utils} from "../../../../utils/Utils";
export const SCHEDULED_DELIVERY_START_DATE_FORMAT = "YYYY-MM-DD HH:mm";
export const SCHEDULED_DELIVERY_END_DATE_FORMAT = "HH:mm";
export const DELIMITER = " - ";

export const isMissing = (driverSummary, trackingId) => {
    return Boolean(ValidationUtils.isNotEmptyObject(driverSummary) && ReconciliationStatus.MISSING in driverSummary && driverSummary[ReconciliationStatus.MISSING].includes(trackingId))
}

export const skipStatusToSummary = (isResolvingPackages, driverSummary, trackingId) => {
    let finalStatus
    if (isResolvingPackages && isMissing(driverSummary, trackingId)) {
        finalStatus = ReconciliationStatus.MISSING_SKIPPED
    } else {
        finalStatus = ReconciliationStatus.SKIPPED
    }
    return addPackageToSummary(trackingId, driverSummary, finalStatus)
}

export const shouldDisplayScheduledDeliveryWindow = (isScheduledDeliveryDisplayEnabled, scheduledStart, scheduledEnd) => {
    return Boolean (isScheduledDeliveryDisplayEnabled && scheduledStart != null && scheduledEnd != null)
}

export const getScheduledDeliveryWindow = (scheduledStart, scheduledEnd) => {
   return Utils.convertMillisToDate(scheduledStart, SCHEDULED_DELIVERY_START_DATE_FORMAT) + DELIMITER + Utils.convertMillisToDate(scheduledEnd, SCHEDULED_DELIVERY_END_DATE_FORMAT)
}