import React, {useContext, useEffect, useState} from "react"
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {DetailsCardView, Loader} from "@amzn/dolphin-uiweb-framework";
import {DebriefDriverContext} from "../context/DebriefDriverContext";
import {AppContext} from "../../appContext/context/AppContext";
import NotificationView from "../../appContext/views/NotificationView";
import {ReconciliationStatus} from "./DriverSummaryView/Constants";
import {navigate} from "../../constants/routes";
import {FormattedMessage} from "react-intl";
import {interceptBackButton, interceptBackButtonStop} from "../../Handler/BackPressHandler";
import {PlayerContext} from "../../player/PlayerContext";
import {ApplicationActions} from "../../player/ApplicationActions";

const BulkTransferView = (props) => {
    const {
        state: {
            driverSummary: {[ReconciliationStatus.BULK_RETURNING_TO_STATION]: packages = []} = {},
            debriefDetails: {packageDetailsMap = {}} = {}, transporter: {transporterId = ""} = {},
            requestCompleted, isLoading
        }, debriefDriverActions
    } = useContext(DebriefDriverContext)

    const {appActions} = useContext(AppContext);
    const {dispatchPlayer} = useContext(PlayerContext)
    const [data, setData] = useState([])

    const bulkTransfer = () => {
        const packageDetailsList = packages.map((item) => {
            return {
                scannableId: item,
                shipmentType: packageDetailsMap[item].shipmentType
            }
        })
        const requestData = {
            packageDetailsList: packageDetailsList,
            transporterId: transporterId
        }
        dispatchPlayer({type: ApplicationActions.BULK_TRANSFER});
        debriefDriverActions.bulkReceivePackage(requestData, debriefDriverActions, appActions.setNotification)
    }

    const onBackPressed = () => {
        appActions.openBackDrop()
    }

    useEffect(() => {
        setData([{title: packages.length.toString(), description: <FormattedMessage id="bulk_receive" />}])
        appActions.setBackPress(onBackPressed)
        interceptBackButton();
        return () => {
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])

    useEffect(() => {
        if (requestCompleted) {
            debriefDriverActions.resetRequest()
            props.history.push(navigate.BULK_TRANSFER_SUCCESS);
        }
    }, [requestCompleted]);

    const displayDefaultComponent = () => {
        return (
            <Column height="100%" heights="fill">
                <Column spacingInset="medium">
                    <NotificationView/>
                    <Text alignment="center" type="h300"><FormattedMessage id="bulk_transfer_title" /></Text>
                    <DetailsCardView snapshots={data}/>
                </Column>
                <Column alignmentHorizontal="center" alignmentVertical="bottom" spacingInset="medium">
                    <Row width="100%" widths="fill" alignmentVertical="bottom">
                        <Button size="large" onClick={bulkTransfer} type="primary"><FormattedMessage id="bulk_transfer_button" /></Button>
                    </Row>
                </Column>
            </Column>
        )
    }

    return (
        <Column height="100%" heights="fill">
            {isLoading && <Loader/>}
            {displayDefaultComponent()}
        </Column>
    )
}

export default BulkTransferView;
