import beep from "../../audio/res/beep.ogg";
import beep_error from "../../audio/res/beep_error.ogg";
import beep_warning from "../../audio/res/beep_warning.ogg";
import silent from "../../audio/res/silent.mp3";
import {Logger} from "@amzn/dolphin-web-framework";
const VIBRATION_DURATION_IN_MS = 500;

function initializeSoundObject(resource) {
    let audio = new Audio(resource);
    audio.preload = 'auto';
    return audio
}

let beep_sound = initializeSoundObject(beep);
let beep_error_sound = initializeSoundObject(beep_error);
let beep_warning_sound = initializeSoundObject(beep_warning);
let silent_sound = initializeSoundObject(silent);

export const SoundType = {
    BEEP: beep_sound,
    BEEP_ERROR: beep_error_sound,
    BEEP_WARNING: beep_warning_sound,
    SILENT: silent_sound,
};

export function playErrorSound() {
    SoundType.BEEP_ERROR.play().catch(error => {
        Logger.log.warn("Unable to play sound : " + error);
    });
    window.navigator.vibrate(VIBRATION_DURATION_IN_MS);
}

export function playWarningSound() {
    SoundType.BEEP_WARNING.play().catch(error => {
        Logger.log.warn("Unable to play sound : " + error);
    });
    window.navigator.vibrate(VIBRATION_DURATION_IN_MS);
}

export function playSuccessSound() {
    SoundType.BEEP.play().catch(error => {
        Logger.log.warn("Unable to play sound : " + error);
    });
    window.navigator.vibrate(VIBRATION_DURATION_IN_MS);
}