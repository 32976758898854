import {axiosGET, axiosPOST} from "../../utils/Network/NetworkInterface";
import {driftEndpoints} from "../../constants/endpoints";
import {METRICS} from "../../constants/Metrics";
import {Modules} from "../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";

export async function receivePackage(receivePackageActions, appNotification, trackingId, transporterId) {
    let startTime = Date.now()
    receivePackageActions.isLoading()
    const data = {
        "includeShipmentDetails": true,
        "packageQualifier": {
            "scannableId": trackingId
        }
    }

    if(transporterId) {
        data.transporterId = transporterId;
    }

    const response = await axiosPOST(METRICS.RECEIVE_PACKAGE, driftEndpoints.RECEIVE_PACKAGE, data, receivePackageActions, appNotification)

    if (response.status === 200) {
        MobileAnalyticsHelper.executeAPIAnalytics(Modules.RECEIVE_PACKAGE, startTime);
        receivePackageActions.receivePackageResponse(response.data)
        return
    }
    MobileAnalyticsHelper.executeAPIAnalytics(Modules.RECEIVE_PACKAGE, startTime, true);
}

export async function getTargetStatusList(receivePackageActions, appNotification, trackingId) {
    let startTime = Date.now()
    receivePackageActions.isLoading()
    const data = {
        "scannableIdList": [
            trackingId
        ]
    }

    const response = await axiosPOST(METRICS.GET_TARGET_STATUS_LIST, driftEndpoints.GET_TARGET_STATUS_LIST, data, receivePackageActions, appNotification)
    if (response.status === 200) {
        MobileAnalyticsHelper.executeAPIAnalytics(Modules.GET_TARGET_STATUS_LIST, startTime);
        receivePackageActions.getTargetStatusResponse(response.data)
        return;
    }
    MobileAnalyticsHelper.executeAPIAnalytics(Modules.GET_TARGET_STATUS_LIST, startTime, true);
}


export async function updateTargetStatus(receivePackageActions, appNotification, requestData) {
    let startTime = Date.now()
    receivePackageActions.isLoading()

    const response = await axiosPOST(METRICS.UPDATE_TARGET_STATUS, driftEndpoints.UPDATE_TARGET_STATUS, requestData, receivePackageActions, appNotification)
    if (response.status === 200) {
        MobileAnalyticsHelper.executeAPIAnalytics(Modules.UPDATE_TARGET_STATUS, startTime);
        receivePackageActions.updateTargetStatusResponse(response.data.updateShipmentsStatus)
        return;
    }
    MobileAnalyticsHelper.executeAPIAnalytics(Modules.UPDATE_TARGET_STATUS, startTime, true);
}

export async function getPackageDetails(receivePackageActions, appNotification, data) {
    let startTime = Date.now()
    receivePackageActions.isLoading()

    const params = {
        params: data
    }

    const response = await axiosGET(METRICS.GET_PACKAGE_DETAILS, driftEndpoints.GET_PACKAGE_DETAILS, params, receivePackageActions, appNotification)

    if (response.status === 200) {
        MobileAnalyticsHelper.executeAPIAnalytics(Modules.GET_PACKAGE_DETAILS, startTime);
        receivePackageActions.getPackageDetailsResponse(response.data)
        return;
    }
    MobileAnalyticsHelper.executeAPIAnalytics(Modules.GET_PACKAGE_DETAILS, startTime, true);
}