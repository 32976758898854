import React from "react"
import {FormattedMessage} from "react-intl";

export const HomePageInit = {
    pageTitle: <FormattedMessage id="pre_rts_header_message_receive"/>,
    tasks: [
        {
            title: <FormattedMessage id="receive_packages"/>,
            description: <FormattedMessage id="receive_packages_description"/>,
            href: "/receivePackage"
        },
        {
            title: <FormattedMessage id="receive_driver"/>,
            description: <FormattedMessage id="receive_driver_description"/>,
            href: "/scanDriverBadgeId"
        },
        {
            title: <FormattedMessage id="receive_containers"/>,
            description: <FormattedMessage id="receive_containers_description"/>,
            href: "/receiveContainer"
        }
    ]
}