import {actionType} from "../../constants/actionType";
import {DEFAULT} from "../../constants/constants";


export const AppReducer = (state, action) => {
    switch (action.type) {
        case actionType.SET_NOTIFICATION:
            return {...state, displayNotification: action.data}

        case actionType.RESET_NOTIFICATION:
            return {...state, displayNotification: {}}

        case actionType.ENABLE_HELP:
            return {...state, helpActive: true}

        case actionType.DISABLE_HELP:
            return {...state, helpActive: false}

        case actionType.SET_HELP_OPTIONS:
            return {...state, helpOptions: [...state.helpOptions, ...action.helpOptions]}

        case actionType.RESET_HELP_OPTIONS:
            return {...state, helpOptions: []};

        /**
         * Backdrop options
         */
        case actionType.OPEN_BACK_DROP:
            return {...state, backOptions: {...state.backOptions, open: true}};

        case actionType.CLOSE_BACK_DROP:
            return {...state, backOptions: {...state.backOptions, open: false}}

        case actionType.SET_BACK_PRESS:
            return {...state, onBackPressed: action.data}

        case actionType.SET_NAV_THEME:
            return {...state, navTheme: action.data}

        case actionType.RESET_STATE:
            return {
                ...state,
                displayNotification: {},
                helpActive: false,
                helpOptions: [],
                navTheme: DEFAULT,
                onBackPressed: null,
            }

        default:
            return state
    }
}