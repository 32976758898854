import React from "react";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";

const PackageDetailsSnapshotView = (props) => {
    const packageSnapshots = props.packageDetails
        .filter(data => data.title && data.description)
        .map((data, index) => {
                let {title, description} = data;
                return (
                    <Column key={index} spacing="xxsmall">
                        <Text type="b200">{title}</Text>
                        <Text type="h200">{description}</Text>
                    </Column>
                )
            }
        );
    return (
        <Column spacing="large">
            {packageSnapshots}
        </Column>
    )
}

export default PackageDetailsSnapshotView