export const ApplicationActions = {
    TASK_SELECTED: "TASK_SELECTED",
    HELP_SELECTED: "HELP_SELECTED",
    BACK_SELECTED: "BACK_SELECTED",
    HELP_OPTION_SELECTED: "HELP_OPTION_SELECTED",
    HELP_CLOSED: "HELP_CLOSED",
    BACK_OPTION_SELECTED: "BACK_OPTION_SELECTED",
    RETURN_OVERVIEW_ACKNOWLEDGE: "RETURN_OVERVIEW_ACKNOWLEDGE",
    DRIVER_SELECT_ACKNOWLEDGE: "DRIVER_SELECT_ACKNOWLEDGE",
    ENTER_DRIVER_DETAILS: "ENTER_DRIVER_DETAILS",
    PACKAGE_SCANNED: "PACKAGE_SCANNED",
    ALL_PACKAGE_RETURNED: "ALL_PACKAGE_RETURNED",
    OVERRIDE_TYPE_SELECT_STATION: "OVERRIDE_TYPE_SELECT_STATION",
    OVERRIDE_TYPE_SELECT_ON_ROAD: "OVERRIDE_TYPE_SELECT_ON_ROAD",
    STATUS_OVERRIDE: "STATUS_OVERRIDE",
    DRIVER_ID_SCAN: "DRIVER_ID_SCAN",
    DRIVER_ID_CONFIRM: "DRIVER_ID_CONFIRM",
    OVERRIDE_SELECTED: "OVERRIDE_SELECTED",
    DEBRIEF_COMPLETE: "DEBRIEF_COMPLETE",
    RETURN_SUMMARY: "RETURN_SUMMARY",
    RESOLVE_PACKAGES: "RESOLVE_PACKAGES",
    PACKAGE_SKIPPED: "PACKAGE_SKIPPED",
    DRIVER_SEARCH: "DRIVER_SEARCH",
    PACKAGE_DETAILS_CLOSED: "PACKAGE_DETAILS_CLOSED",
    BULK_TRANSFER: "BULK_TRANSFER",
    BULK_TRANSFER_ACKNOWLEDGE: "BULK_TRANSFER_ACKNOWLEDGE",
}
