import React, {useContext, useEffect, useState} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {HeaderView} from "@amzn/dolphin-uiweb-framework";
import {DebriefDriverContext} from "../../../../debriefDriver/context/DebriefDriverContext";
import {navigate} from "../../../../constants/routes";
import {FormattedMessage, injectIntl} from "react-intl";
import {interceptBackButton, interceptBackButtonStop} from "../../../../Handler/BackPressHandler";
import {AppContext} from "../../../../appContext/context/AppContext";
import {PlayerContext} from "../../../../player/PlayerContext";
import {ApplicationActions} from "../../../../player/ApplicationActions";

const ResolvePackageStatusView = (props) => {
    const {state: {unresolvedPackages = []}, debriefDriverActions} = useContext(DebriefDriverContext)
    const {appActions} = useContext(AppContext)
    const {dispatchPlayer} = useContext(PlayerContext);
    const [totalPackages, setTotalPackages] = useState(0)

    const updateStatus = () => {
        dispatchPlayer({type: ApplicationActions.RESOLVE_PACKAGES});
        debriefDriverActions.setResolvingPackages()
        props.history.push(navigate.PACKAGE_OVERVIEW);
    }

    useEffect(() => {
        if(!unresolvedPackages.length) {
            dispatchPlayer({type: ApplicationActions.ALL_PACKAGE_RETURNED, data: {operation: ApplicationActions.DEBRIEF_COMPLETE}});
            props.history.push(navigate.RETURN_COMPLETE)
        } else {
            dispatchPlayer({type: ApplicationActions.ALL_PACKAGE_RETURNED, data: {operation: ApplicationActions.RESOLVE_PACKAGES}});
            setTotalPackages(unresolvedPackages.length)
        }
    }, [])

    const onBackPressed = () => {
        appActions.openBackDrop()
    }

    useEffect(() => {
        appActions.setBackPress(onBackPressed)
        interceptBackButton()
        return () => {
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])

    return (
        <Column height="100%" heights="fill" alignmentVertical="bottom">
            <Column alignmentHorizontal="center" spacingInset="medium">
                <HeaderView primaryTitle={{title: props.intl.formatMessage({id:"unresolved_packages"}, {totalPackages: totalPackages})}}
                            secondaryTitle={{title: props.intl.formatMessage({id:"resolve_packages"}), type: "Bold", size: "Small"}}/>
            </Column>
            <Column alignmentHorizontal="center" alignmentVertical="bottom">
                <Row width="100%" widths="fill" spacingInset="medium" alignmentVertical="bottom">
                    <Button onClick={updateStatus} size="large" type="primary">
                        <FormattedMessage id="btn_resolve_packages"/>
                    </Button>
                </Row>
            </Column>
        </Column>
    )
}

export default injectIntl(ResolvePackageStatusView);
