import {Utils} from "../../../../utils/Utils";
import {shouldDisplayScheduledDeliveryWindow, getScheduledDeliveryWindow} from "./Utils";

const checkAndPush = (objectList, description, title) => {
    if(title) {
        objectList.push({
            description: description,
            title: title
        })
    }
}
export const createPackageSnapshot = (intl, data, isScheduledDeliveryDisplayEnabled) => {
    const snapshotList = []
    checkAndPush(snapshotList, intl.formatMessage({id: "package_id"}), data.scannableId)
    checkAndPush(snapshotList, intl.formatMessage({id: "previous_state"}), data.packageState)
    checkAndPush(snapshotList, intl.formatMessage({id: "previous_reason"}), data.packageReason)
    checkAndPush(snapshotList, intl.formatMessage({id: "address_type_field"}), data.addressType)
    if (shouldDisplayScheduledDeliveryWindow(isScheduledDeliveryDisplayEnabled, data.scheduledDeliveryWindowStart, data.scheduledDeliveryWindowEnd)) {
        const deliveryWindow = getScheduledDeliveryWindow(data.scheduledDeliveryWindowStart, data.scheduledDeliveryWindowEnd)
        checkAndPush(snapshotList, intl.formatMessage({id: "delivery_window"}), deliveryWindow)
    }
    return snapshotList
}

// This view is powered by packageDetails. If packageDetails fails, it fallbacks to receivePackage.
export const createPackageDataSnapshot = (intl, data, isScheduledDeliveryDisplayEnabled) => {
    let snapshotList = [
        {title: intl.formatMessage({id: "shipment_type_field"}), description: data.shipmentType},
        {title: intl.formatMessage({id: "shipment_status_field"}), description: data.packageState},
        {title: intl.formatMessage({id: "reason_code_field"}), description: data.packageReason}
    ]
    if (data.address) {
        snapshotList = snapshotList.concat([
            {title: intl.formatMessage({id: "address_type_field"}), description: data.address.addressType},
            {title: intl.formatMessage({id: "address_field"}), description: data.address.address1},
            {title: intl.formatMessage({id: "address_line_2"}), description: data.address.address2},
            {title: intl.formatMessage({id: "address_line_3"}), description: data.address.address3},
            {title: intl.formatMessage({id: "state"}), description: data.address.state},
            {title: intl.formatMessage({id: "city"}), description: data.address.city},
            {title: intl.formatMessage({id: "postal_code"}), description: data.address.postalCode},
        ])
    }

    snapshotList = snapshotList.concat([{
        title: intl.formatMessage({id: "last_updated_date_field"}),
        description: Utils.convertMillisToDate(data.lastUpdatedTime)
    },
        {title: intl.formatMessage({id: "promise_delivery_date_field"}), description: Utils.convertMillisToDate(data.promiseDeliveryTime)},
        {title: intl.formatMessage({id: "assigned_transporter_field"}), description: data.assignedTransporter},
        {title: intl.formatMessage({id: "order_id_field"}), description: data.orderId},
        {title: intl.formatMessage({id: "tr_id_field"}), description: data.trId}
    ])

    // To handle when keys are different in packageDetails and receivePackage
    const scheduledDeliveryStart = data.scheduledDeliveryDateStart || data.scheduledDeliveryWindowStart
    const scheduledDeliveryEnd = data.scheduledDeliveryDateEnd || data.scheduledDeliveryWindowEnd

    if (shouldDisplayScheduledDeliveryWindow(isScheduledDeliveryDisplayEnabled, scheduledDeliveryStart, scheduledDeliveryEnd)) {
        const deliveryWindow = getScheduledDeliveryWindow(scheduledDeliveryStart, scheduledDeliveryEnd)
        snapshotList = snapshotList.concat([
            {title: intl.formatMessage({id: "delivery_window"}), description: deliveryWindow}
        ])
    }
    return snapshotList
}

export const createPackageTraceSnapshot = (traceData) => {
    return traceData.map(data => ({
        title: `${data.status} - ${data.reason}`,
        description: Utils.convertMillisToDate(data.updateTime)
    }))
}