import {
    getPackageDetails,
    getTargetStatusList,
    receivePackage,
    updateTargetStatus
} from '../network/ReceivePackageNetworkAPI'
import {actionType} from "../../constants/actionType";

export const ReceivePackageReducer = (state, action) => {
    let dispatcher, trackingId, transporterId, debriefGlobalDispatcher, data
    switch (action.type) {
        case actionType.RECEIVE_PACKAGE:
            ({dispatcher, debriefGlobalDispatcher, trackingId, transporterId} = action.data)
            receivePackage(dispatcher, debriefGlobalDispatcher, trackingId, transporterId);
            return {...state, packageData: {}, requestCompleted: false};

        case actionType.SET_PACKAGE_ATTRIBUTES:
            (data = action.data)
            return {...state, packageAttributes: data};

        case actionType.RECEIVE_PACKAGE_RESPONSE:
            return {...state, isLoading: false, packageData: action.data, requestCompleted: true};

        case actionType.GET_TARGET_STATUS_LIST:
            ({dispatcher, debriefGlobalDispatcher, trackingId} = action.data)
            getTargetStatusList(dispatcher, debriefGlobalDispatcher, trackingId);
            return {...state, targetList: {}, requestCompleted: false};

        case actionType.RECEIVED_TARGET_STATUS_LIST:
            return {...state, isLoading: false, targetList: action.data, requestCompleted: true};

        case actionType.UPDATE_STATUS_DATA:
            return {...state, updateStatusData: {...state.updateStatusData, ...action.data}}

        case actionType.UPDATE_TARGET_STATUS:
            ({dispatcher, debriefGlobalDispatcher} = action.data)
            updateTargetStatus(dispatcher, debriefGlobalDispatcher, state.updateStatusData)
            return {...state, requestCompleted: false};

        case actionType.UPDATE_TARGET_STATUS_RESPONSE:
            return {...state, isLoading: false, requestCompleted: true, updateSuccess: action.data};

        case actionType.GET_PACKAGE_DETAILS:
            ({dispatcher, debriefGlobalDispatcher, data} = action.data)
            getPackageDetails(dispatcher, debriefGlobalDispatcher, data)
            return {...state, requestCompleted: false};

        case actionType.GET_PACKAGE_DETAILS_RESPONSE:
            return {...state, isLoading: false, requestCompleted: true, packageDetails: action.data};

        case actionType.RESET_PACKAGE_DETAILS:
            return {...state, packageDetails: null};

        case actionType.LOADING:
            return {...state, isLoading: true, requestCompleted: false};

        case actionType.LOADING_COMPLETE:
            return {...state, isLoading: false};

        case actionType.RESET_REQUEST:
            return {...state, requestCompleted: false}

        case actionType.SET_CATEGORY:
            return {...state, overrideCategory: action.data}

        case actionType.RESET_STATE:
            return {
                isLoading: false,
                packageData: {},
                targetList: {},
                updateStatusData: null,
                requestCompleted: false,
                updateSuccess: false,
                packageDetails: {},
                overrideCategory: null,
                packageAttributes: {}
            }

        default:
            return state;
    }
}