import React, {useContext, useEffect, useState} from "react"
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import {AppContext} from "../context/AppContext";
import {ValidationUtils} from "../../utils/Utils";
import {DEFAULT, ERROR, NotificationType} from "../../constants/constants";
import * as SoundManager from "../../utils/SoundManager";

const NotificationView = (props) => {
    const {state: {displayNotification}, appActions} = useContext(AppContext)
    const [notification, setNotification] = useState({})


    useEffect(() => {
        if (ValidationUtils.isNotEmptyObject(displayNotification)) {
            setNotification(displayNotification)
            playSoundByAlertType(displayNotification.type)
            if(NotificationType.ERROR === displayNotification.type){
                appActions.setNavTheme(ERROR)
            } else {
                appActions.setNavTheme(DEFAULT)
            }
            appActions.resetNotification()
        }
    }, [displayNotification])

    const playSoundByAlertType = (alertType) => {
        switch(alertType) {
            case NotificationType.SUCCESS:
                SoundManager.playSuccessSound();
                break;
            case NotificationType.WARNING:
                SoundManager.playWarningSound();
                break;
            case NotificationType.ERROR:
                SoundManager.playErrorSound();
                break
            default:
                break
        }
    }

    const clearNotification = () => {
        appActions.setNavTheme(DEFAULT)
        setNotification({})
    }

    useEffect(() => {
        return () => {appActions.setNavTheme(DEFAULT)}
    }, [])

    return (
        ValidationUtils.isNotEmptyObject(notification) ?
            <Column width="100%" spacing="none" spacingInset={props.spacingInset}>
                <Alert type={notification.type} size="medium"
                       onClose={clearNotification}><Text>{notification.message}</Text></Alert>
            </Column> : null
    )
}

export default NotificationView;