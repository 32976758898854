import {ApplicationActions} from "./ApplicationActions";
import {FeatureManager, Logger, PlayerHelper} from "@amzn/dolphin-web-framework";
import {
    BackOption,
    BulkTransferOption,
    HelpOption,
    mapPackageState, PackageState,
    PlayerConstants,
    StatusOverrideOption
} from "./PlayerConstant";
import {ReceiveContainerResponseCode, ResponseStatus} from "../receiveContainer/view/Constants";
import {CATEGORY_INSTATION, CATEGORY_ONROAD} from "../constants/constants";
import {navigate} from "../constants/routes";
import {ResponseCode} from "../receivePackage/views/package/ScanPackageView/Constants";

export const PlayerReducer = (state, action) => {
    try{
        if (window && window.DolphinPlayer) {
            let appMessageEvent, appMessageState;
            Logger.log.info(`Player Action = ${JSON.stringify(action)}. Player state = ${JSON.stringify(state)}`);

            state.manualHelpEnabled = FeatureManager.isFeatureEnabled(FeatureManager.Features.MANUAL_TRACKING_ID_HELP_ENABLED);
            state.enabledSideline = FeatureManager.isFeatureEnabled(FeatureManager.Features.RTS_FIXIT_INTEGRATION)

            switch (action.type) {
                case ApplicationActions.TASK_SELECTED: {
                    if (!state.isTaskSelected) {
                        if (action.data.taskId === PlayerConstants.DEBRIEF_PACKAGES) {
                            appMessageEvent = new window.DolphinPlayer.RTS.TaskSelectedEvent(window.DolphinPlayer.RTS.ReceiveTask.DEBRIEF_PACKAGES);
                            appMessageState = new window.DolphinPlayer.RTS.PackageScanState(state.isFirstPackage, false, null, null);
                            PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                            state = {...state, currentState: appMessageState, backEnabled: true, isTaskSelected: true, helpEnabled: state.manualHelpEnabled}
                        }
                        else if (action.data.taskId === PlayerConstants.DEBRIEF_DRIVER) {
                            appMessageEvent = new window.DolphinPlayer.RTS.TaskSelectedEvent(window.DolphinPlayer.RTS.ReceiveTask.DEBRIEF_DRIVER);
                            appMessageState = new window.DolphinPlayer.RTS.DriverIdScanState(false);
                            PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                            state = {...state, currentState: appMessageState, backEnabled: true, isTaskSelected: true}
                        }
                    }
                    return state;
                }
                case ApplicationActions.HELP_SELECTED: {
                    if (state.helpEnabled) {
                        appMessageEvent = new window.DolphinPlayer.RTS.HelpCenterOpenedEvent()
                        appMessageState = new window.DolphinPlayer.RTS.HelpCenterState(window.DolphinPlayer.RTS.HelpCenterContext.PACKAGE_SCAN);
                    } else {
                        appMessageEvent = new window.DolphinPlayer.RTS.HelpCenterOpenedEvent()
                        appMessageState = new window.DolphinPlayer.RTS.HelpCenterState(window.DolphinPlayer.RTS.HelpCenterContext.UNKNOWN);
                    }
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return state;
                }
                case ApplicationActions.HELP_CLOSED: {
                    appMessageEvent = new window.DolphinPlayer.RTS.ActionDeclinedEvent();
                    if(state.currentState === undefined) {
                        appMessageState = new window.DolphinPlayer.RTS.TaskSelectState();
                    } else {
                        appMessageState = state.currentState
                    }
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return state;
                }
                case ApplicationActions.HELP_OPTION_SELECTED: {
                    if (action.data.operation === HelpOption.OVERRIDE_PACKAGE_STATUS) {
                        appMessageEvent = new window.DolphinPlayer.RTS.HelpCenterOptionSelectedEvent(window.DolphinPlayer.RTS.HelpCenterOption.OVERRIDE_PACKAGE_STATUS)
                        appMessageState = new window.DolphinPlayer.RTS.StatusOverrideConfirmState(true, false);
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, backEnabled: true, currentState: appMessageState, helpEnabled: state.manualHelpEnabled};
                    } else if(action.data.operation === HelpOption.VIEW_PACKAGE_DETAILS) {
                        appMessageEvent = new window.DolphinPlayer.RTS.HelpCenterOptionSelectedEvent(window.DolphinPlayer.RTS.HelpCenterOption.VIEW_PACKAGE_DETAILS)
                        appMessageState = new window.DolphinPlayer.RTS.PackageDetailsState(false);
                    } else if(action.data.operation === HelpOption.MANUAL_SCAN) {
                        appMessageEvent = new window.DolphinPlayer.RTS.HelpCenterOptionSelectedEvent(window.DolphinPlayer.RTS.HelpCenterOption.MANUAL_PACKAGE_SCAN)
                        appMessageState = new window.DolphinPlayer.RTS.ManualPackageScanState();
                    }
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return state;
                }
                case ApplicationActions.BACK_SELECTED: {
                    appMessageEvent = new window.DolphinPlayer.RTS.BackActionSelectedEvent();
                    if(state.backEnabled){
                        appMessageState = new window.DolphinPlayer.RTS.BackActionConfirmState();
                    } else {
                        appMessageState = (state.backState === undefined)? new window.DolphinPlayer.RTS.TaskSelectState(): state.backState;
                    }
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return state;
                }
                case ApplicationActions.BACK_OPTION_SELECTED: {
                    if (action.data.operation === BackOption.EXIT) {
                        appMessageEvent = new window.DolphinPlayer.RTS.ActionDeclinedEvent();
                        appMessageState = new window.DolphinPlayer.RTS.TaskSelectState();
                    } else if(action.data.operation === BackOption.CONTINUE || action.data.operation === BackOption.CLOSE) {
                        appMessageEvent = new window.DolphinPlayer.RTS.ActionAcknowledgedEvent();
                        appMessageState = state.currentState
                    }
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return {...state, backState: appMessageState};
                }

                case ApplicationActions.PACKAGE_SCANNED: {
                    const isError = action.data.userOutput.responseStatus === ResponseStatus.ERROR;
                    state.packageState = mapPackageState(action.data.userOutput.displayOutput.currentStatus)
                    appMessageEvent = (state.isManual) ? new window.DolphinPlayer.RTS.BarcodeEnteredEvent(!isError): new window.DolphinPlayer.RTS.BarcodeScannedEvent(!isError);
                    if (isError) {
                        if (action.data.userOutput.responseCode === ReceiveContainerResponseCode.INVALID_TRACKING_ID) {
                            appMessageState = new window.DolphinPlayer.RTS.PackageScanState(state.isFirstPackage, true, state.packageState, window.DolphinPlayer.RTS.PackageScanErrorType.INVALID_BARCODE);
                            PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                            return {...state, currentState: appMessageState, backEnabled: true, isManual: false, helpEnabled: state.manualHelpEnabled};
                        } else if(action.data.userOutput.responseCode === ResponseCode.WRONG_STATION){
                            appMessageState = new window.DolphinPlayer.RTS.StatusOverrideConfirmState(true, true, window.DolphinPlayer.RTS.StatusOverrideConfirmErrorType.WRONG_STATION);
                            PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                            return {...state, currentState: appMessageState, backEnabled: true, isManual: false, helpEnabled: state.manualHelpEnabled};
                        } else if(action.data.userOutput.responseCode === ResponseCode.DAMAGED) {
                            if(state.enabledSideline) {
                                state.packageState = mapPackageState(PackageState.PROBLEM_SOLVE)
                                appMessageState = new window.DolphinPlayer.RTS.PackageScanState(state.isFirstPackage, true, state.packageState, window.DolphinPlayer.RTS.PackageScanErrorType.PACKAGE_DAMAGED);
                                PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                                return {...state, currentState: appMessageState, backEnabled: true, isManual: false, helpEnabled: true };
                            }
                            else {
                                appMessageState = new window.DolphinPlayer.RTS.StatusOverrideConfirmState(true, true, window.DolphinPlayer.RTS.StatusOverrideConfirmErrorType.DAMAGED);
                                PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                                return {...state, currentState: appMessageState, backEnabled: true, isManual: false, helpEnabled: state.manualHelpEnabled };
                            }
                        } else {
                            appMessageState = new window.DolphinPlayer.RTS.StatusOverrideConfirmState(true, true, window.DolphinPlayer.RTS.StatusOverrideConfirmErrorType.UNKNOWN);
                            PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                            return {...state, currentState: appMessageState, backEnabled: true, isManual: false, helpEnabled: state.manualHelpEnabled };
                        }
                    } else {
                        appMessageState = new window.DolphinPlayer.RTS.PackageScanState(false, false, state.packageState, null);
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, isFirstPackage: false, currentState: appMessageState, backEnabled: true, helpEnabled: true, isManual: false};
                    }
                }
                case ApplicationActions.STATUS_OVERRIDE: {
                    if (action.data.operation === StatusOverrideOption.PACKAGE_DETAILS) {
                        appMessageEvent = new window.DolphinPlayer.RTS.StatusOverrideConfirmOptionSelectedEvent(window.DolphinPlayer.RTS.StatusOverrideConfirmOption.VIEW_PACKAGE_DETAILS);
                        appMessageState = new window.DolphinPlayer.RTS.PackageDetailsState(action.data.isSkipEnabled);
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state,
                            backEnabled: false, currentState: appMessageState, helpEnabled: state.manualHelpEnabled,
                            backState: new window.DolphinPlayer.RTS.StatusOverrideConfirmState(true, false)
                        };
                    } else if (action.data.operation === StatusOverrideOption.UPDATE) {
                        appMessageEvent = new window.DolphinPlayer.RTS.StatusOverrideConfirmOptionSelectedEvent(window.DolphinPlayer.RTS.StatusOverrideConfirmOption.UPDATE_STATUS);
                        if (action.data.areOptionsAvailable) {
                            appMessageState = new window.DolphinPlayer.RTS.OverrideTypeSelectState();
                        } else {
                            appMessageState = new window.DolphinPlayer.RTS.StatusOverrideConfirmState(false, false);
                        }
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state,
                            backEnabled: false, currentState: appMessageState, helpEnabled: state.manualHelpEnabled,
                            backState: new window.DolphinPlayer.RTS.StatusOverrideConfirmState(true, false)
                        };
                    } else if (action.data.operation === StatusOverrideOption.CANCEL) {
                        appMessageEvent = new window.DolphinPlayer.RTS.ActionDeclinedEvent();
                        if(action.data.view === PlayerConstants.PACKAGE_OVERVIEW) {
                            appMessageState = new window.DolphinPlayer.RTS.PackageScanState(false, false, state.packageState, null);
                        } else if(action.data.view === PlayerConstants.SELECT_STATUS){
                            appMessageState = new window.DolphinPlayer.RTS.StatusOverrideConfirmState(true, false);
                        }
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, backEnabled: true, currentState: appMessageState, helpEnabled: state.manualHelpEnabled};
                    }
                    return state;
                }
                case ApplicationActions.OVERRIDE_TYPE_SELECT_ON_ROAD: {
                    appMessageEvent = new window.DolphinPlayer.RTS.OverrideTypeOptionSelectedEvent(
                        window.DolphinPlayer.RTS.OverrideType.ON_ROAD_OVERRIDE);
                    appMessageState = new window.DolphinPlayer.RTS.OnRoadOverrideState(false);
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return {...state, backState: new window.DolphinPlayer.RTS.OverrideTypeSelectState(),
                        currentState: appMessageState, backEnabled: false, helpEnabled: state.manualHelpEnabled};
                }
                case ApplicationActions.OVERRIDE_TYPE_SELECT_STATION: {
                    appMessageEvent = new window.DolphinPlayer.RTS.OverrideTypeOptionSelectedEvent(
                        window.DolphinPlayer.RTS.OverrideType.IN_STATION_OVERRIDE);
                    appMessageState = new window.DolphinPlayer.RTS.InStationOverrideState(false);
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return {...state, backState: new window.DolphinPlayer.RTS.OverrideTypeSelectState(),
                        currentState: appMessageState, backEnabled: false, helpEnabled: state.manualHelpEnabled};
                }
                case ApplicationActions.PACKAGE_DETAILS_CLOSED: {
                    appMessageEvent = new window.DolphinPlayer.RTS.ActionAcknowledgedEvent();
                    appMessageState = new window.DolphinPlayer.RTS.PackageScanState(state.isFirstPackage, false, state.packageState, null);
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return {...state, currentState: appMessageState, helpEnabled: true, backEnabled: true};
                }
                case ApplicationActions.OVERRIDE_SELECTED: {
                    if (action.data.category === CATEGORY_ONROAD) {
                        appMessageEvent = new window.DolphinPlayer.RTS.OnRoadOverrideSelectedEvent();
                    } else if (action.data.category === CATEGORY_INSTATION) {
                        appMessageEvent = new window.DolphinPlayer.RTS.InStationOverrideSelectedEvent();
                    }
                    if (action.data.error) {
                        if (action.data.category === CATEGORY_ONROAD) {
                            appMessageState = new window.DolphinPlayer.RTS.OnRoadOverrideState(true);
                        } else if (action.data.category === CATEGORY_INSTATION) {
                            appMessageState = new window.DolphinPlayer.RTS.InStationOverrideState(true);
                        }
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, backState: new window.DolphinPlayer.RTS.OverrideTypeSelectState(), currentState: appMessageState, helpEnabled: state.manualHelpEnabled, backEnabled: false};
                    } else {
                        const nextState = action.data.next;
                        if (nextState === navigate.RECEIVE_PACKAGE) {
                            appMessageState = new window.DolphinPlayer.RTS.PackageScanState(state.isFirstPackage, false, null, null);
                            PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                            return {...state, currentState: appMessageState, helpEnabled: true, backEnabled: true};
                        } else if (nextState === navigate.PACKAGE_OVERVIEW) {
                            appMessageState = new window.DolphinPlayer.RTS.StatusOverrideConfirmState(true, false)
                        } else if(nextState === navigate.RETURN_COMPLETE) {
                            appMessageState = new window.DolphinPlayer.RTS.DebriefCompleteState();
                        }
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, currentState: appMessageState, helpEnabled: state.manualHelpEnabled, backEnabled: true};
                    }
                }

                case ApplicationActions.DRIVER_ID_SCAN: {
                    const isError = action.data.error;
                    appMessageEvent = new window.DolphinPlayer.RTS.BarcodeScannedEvent(!isError);
                    if (isError) {
                        appMessageState = new window.DolphinPlayer.RTS.DriverIdScanState(true);
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, backEnabled: true, helpEnabled: false, currentState: appMessageState};
                    } else {
                        appMessageState = new window.DolphinPlayer.RTS.DriverIdConfirmState();
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, backEnabled: false, helpEnabled: false, backState: state.currentState, currentState: appMessageState};
                    }
                }
                case ApplicationActions.DRIVER_ID_CONFIRM: {
                    if (action.data.option === PlayerConstants.CONFIRM_DRIVER) {
                        appMessageEvent = new window.DolphinPlayer.RTS.DriverIdConfirmOptionSelectedEvent(window.DolphinPlayer.RTS.DriverIdConfirmOption.CONFIRM_IDENTITY);
                        appMessageState = new window.DolphinPlayer.RTS.ReturnOverviewState();
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, backEnabled: true, helpEnabled: false, currentState: appMessageState};
                    } else {
                        appMessageEvent = new window.DolphinPlayer.RTS.DriverIdConfirmOptionSelectedEvent(window.DolphinPlayer.RTS.DriverIdConfirmOption.SELECT_ANOTHER_DRIVER);
                        appMessageState = new window.DolphinPlayer.RTS.DriverIdScanState(false);
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, backEnabled: true, helpEnabled: false, backState: state.currentState, currentState: appMessageState};
                    }
                }
                case ApplicationActions.ENTER_DRIVER_DETAILS: {
                    appMessageEvent = new window.DolphinPlayer.RTS.DriverIdScanOptionSelectedEvent(
                        window.DolphinPlayer.RTS.DriverIdScanOption.ENTER_DRIVER_DETAILS);
                    appMessageState = new window.DolphinPlayer.RTS.DriverSearchState();
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return {...state, backEnabled: true, helpEnabled: false, currentState: appMessageState};
                }
                case ApplicationActions.DRIVER_SEARCH: {
                    appMessageEvent = new window.DolphinPlayer.RTS.DriverSearchedEvent()
                    appMessageState = new window.DolphinPlayer.RTS.DriverSelectState();
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return {...state, backEnabled: true, helpEnabled: false, currentState: appMessageState};
                }
                case ApplicationActions.DRIVER_SELECT_ACKNOWLEDGE: {
                    appMessageEvent = new window.DolphinPlayer.RTS.DriverSelectedEvent();
                    appMessageState = new window.DolphinPlayer.RTS.ReturnOverviewState();
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return {...state, backEnabled: true, helpEnabled: false, currentState: appMessageState};
                }
                case ApplicationActions.RETURN_OVERVIEW_ACKNOWLEDGE: {
                    if(action.data.bulkPackages) {
                        appMessageEvent = new window.DolphinPlayer.RTS.ActionAcknowledgedEvent();
                        appMessageState = new window.DolphinPlayer.RTS.BulkTransferState()
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, backEnabled: true, helpEnabled: false, currentState: appMessageState};
                    } else {
                        appMessageEvent = new window.DolphinPlayer.RTS.ActionAcknowledgedEvent();
                        appMessageState = new window.DolphinPlayer.RTS.PackageScanState(state.isFirstPackage, false, state.packageState, null)
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, backEnabled: true, helpEnabled: state.manualHelpEnabled, currentState: appMessageState};
                    }
                }
                case ApplicationActions.BULK_TRANSFER: {
                    appMessageEvent = new window.DolphinPlayer.RTS.ActionAcknowledgedEvent()
                    appMessageState = new window.DolphinPlayer.RTS.BulkTransferCompleteState();
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return {...state, backEnabled: true, helpEnabled: false, currentState: appMessageState};
                }
                case ApplicationActions.BULK_TRANSFER_ACKNOWLEDGE: {
                    if(action.data.operation === BulkTransferOption.DEBRIEF_DRIVER) {
                        appMessageEvent = new window.DolphinPlayer.RTS.BulkTransferCompleteOptionSelectedEvent(window.DolphinPlayer.RTS.DEBRIEF_ANOTHER_DRIVER)
                        appMessageState = new window.DolphinPlayer.RTS.DriverIdScanState(false);
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, backEnabled: true, helpEnabled: false, currentState: appMessageState};
                    } else if(action.data.operation === BulkTransferOption.CONTINUE_RECEIVING) {
                        appMessageEvent = new window.DolphinPlayer.RTS.BulkTransferCompleteOptionSelectedEvent(window.DolphinPlayer.RTS.CONTINUE_RECEIVING)
                        appMessageState = new window.DolphinPlayer.RTS.PackageScanState(state.isFirstPackage, false, state.packageState, null)
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, backEnabled: true, helpEnabled: true, currentState: appMessageState};
                    }
                    return state;
                }
                case ApplicationActions.ALL_PACKAGE_RETURNED: {
                    appMessageEvent = new window.DolphinPlayer.RTS.ActionAcknowledgedEvent();
                    if(action.data.operation === ApplicationActions.RESOLVE_PACKAGES) {
                        appMessageState = new window.DolphinPlayer.RTS.ResolvePackagesState();

                    } else if(action.data.operation === ApplicationActions.DEBRIEF_COMPLETE) {
                        appMessageState = new window.DolphinPlayer.RTS.DebriefCompleteState();
                    }
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return {...state, backEnabled: true, helpEnabled: state.manualHelpEnabled, currentState: appMessageState};
                }
                case ApplicationActions.DEBRIEF_COMPLETE: {
                    appMessageEvent = new window.DolphinPlayer.RTS.ActionAcknowledgedEvent();
                    appMessageState = new window.DolphinPlayer.RTS.ReturnSummaryState();
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return {...state, backEnabled: true, helpEnabled: state.manualHelpEnabled, currentState: appMessageState};
                }
                case ApplicationActions.RESOLVE_PACKAGES: {
                    appMessageEvent = new window.DolphinPlayer.RTS.ActionAcknowledgedEvent();
                    appMessageState = new window.DolphinPlayer.RTS.StatusOverrideConfirmState(true, false);
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return {...state, backEnabled: true, helpEnabled: state.manualHelpEnabled, currentState: appMessageState};
                }
                case ApplicationActions.RETURN_SUMMARY: {
                    if (action.data === navigate.SCAN_DRIVER_BY_BADGE_ID) {
                        appMessageEvent = new window.DolphinPlayer.RTS.ReturnSummaryOptionSelectedEvent(
                            window.DolphinPlayer.RTS.ReturnSummaryOption.DEBRIEF_ANOTHER_DRIVER);
                        appMessageState = new window.DolphinPlayer.RTS.DriverIdScanState(false);
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, backEnabled: true, helpEnabled: state.manualHelpEnabled, currentState: appMessageState, packageState: null};
                    } else {
                        appMessageEvent = new window.DolphinPlayer.RTS.ReturnSummaryOptionSelectedEvent(
                            window.DolphinPlayer.RTS.ReturnSummaryOption.EXIT);
                        appMessageState = new window.DolphinPlayer.RTS.TaskSelectState();
                        PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                        return {...state, backEnabled: true, helpEnabled: false, currentState: appMessageState, packageState: null};
                    }
                }
                case ApplicationActions.PACKAGE_SKIPPED: {
                    appMessageEvent = new window.DolphinPlayer.RTS.ActionAcknowledgedEvent();
                    if (action.data) {
                        appMessageState = new window.DolphinPlayer.RTS.DebriefCompleteState();
                    } else {
                        appMessageState = new window.DolphinPlayer.RTS.StatusOverrideConfirmState(true, false);
                    }
                    PlayerHelper.sendMessage(appMessageEvent, appMessageState);
                    return {...state, backEnabled: true, helpEnabled: state.manualHelpEnabled, currentState: appMessageState};
                }
                default: {
                    Logger.log.info("State is not currently mapped in PlayerReducer")
                }
            }
        }
        return state;
    } catch (error) {
        Logger.log.info("Player app message exception", error)
    }

}
