import React from "react"
import image from "../../../../images/ic_package_scan.png";
import {navigate} from "../../../constants/routes";
import {FormattedMessage} from "react-intl";


export const debriefDriverInit = (props) => {
    const enterDriverDetails = () => {
        props.history.push(navigate.SEARCH_DRIVER_WITH_KEYWORD);
    }

    return ({
        primaryTitle: {
            title: <FormattedMessage id="confirm_returning_driver" />
        },
        secondaryTitle: {
            title: <FormattedMessage id={"scan_driver_id"} />,
            type: "Bold",
            size: "Medium"
        },
        image: image,
        button: {
            title: <FormattedMessage id={"enter_driver_details_instead"} />,
            onClick: enterDriverDetails
        }
    })
}