import React, {createContext, useReducer} from "react";
import {ReceiveContainerReducer} from "../reducers/ReceiveContainerReducer";
import {actionType} from "../../constants/actionType";

export const ReceiveContainerContext = createContext();

const ReceiveContainerContextProvider = (props) => {
    const initState = {
        isLoading: false,
        containerReceive: {},
        requestCompleted: false
    };
    const [state, dispatch] = useReducer(ReceiveContainerReducer, initState);

    const receiveContainerActions = {
        fetchContainer: (trackingId, dispatcher, debriefGlobalDispatcher) => {
            dispatch({
                type: actionType.RECEIVE_CONTAINER,
                data: {dispatcher: dispatcher, debriefGlobalDispatcher: debriefGlobalDispatcher, trackingId: trackingId}
            })
        },
        fetchContainerResponse: (responseData) => {
            dispatch({type: actionType.RECEIVE_CONTAINER_RESPONSE, data: responseData})
        },
        isLoading: () => {
            dispatch({type: actionType.LOADING})
        },
        loadingComplete: () => {
            dispatch({type: actionType.LOADING_COMPLETE})
        },
        resetRequest: () => {
            dispatch({type: actionType.RESET_REQUEST})
        },
        resetState: () => {
            dispatch({type: actionType.RESET_STATE})
        }
    }

    return (
        <ReceiveContainerContext.Provider value={{state, receiveContainerActions}}>
            {props.children}
        </ReceiveContainerContext.Provider>
    )
}

export default ReceiveContainerContextProvider;