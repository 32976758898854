import React, {useContext} from "react"
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import closeTokens from "@amzn/meridian-tokens/base/icon/close";
import Row from "@amzn/meridian/row";
import Modal from "@amzn/meridian/modal";
import {THEME_REGULAR_NAV} from "../constants/constants";
import {FormattedMessage} from "react-intl";
import {PlayerContext} from "../player/PlayerContext";
import {ApplicationActions} from "../player/ApplicationActions";

const DialogHead = (props) => {
    const {dispatchPlayer} = useContext(PlayerContext);
    return (
        <Modal open={true} bodySpacingInset="none" scrollContainer="viewport" closeLabel="Close" describedById="modal-description">
            <Row backgroundColor={THEME_REGULAR_NAV} width="100%" widths={["fill"]} spacingInset="medium" spacing="none">
                <Text type="h200" color={"inverted"} alignment={"center"}>
                    <FormattedMessage id="operation_return_to_station_renamed" />
                </Text>
                <div style={{position: "absolute", right: 0}}>
                    <Row spacingInset={"small"} spacing={"none"}>
                        <Button label={"close"} type="icon" color={"inverted"} onClick={
                            ()=> {dispatchPlayer({type: ApplicationActions.PACKAGE_DETAILS_CLOSED}); props.onClose()}}>
                            <Text color={"inverted"}><Icon tokens={closeTokens}/></Text>
                        </Button>
                    </Row>
                </div>
            </Row>
            {props.children}
        </Modal>
    )
}

export default DialogHead
