import React, {createContext, useReducer, useContext} from "react";
import {DebriefDriverReducer} from "../reducers/DebriefDriverReducer";
import {actionType} from "../../constants/actionType";
import {driverReturnProgress, getUnresolvedPackages} from "../views/DriverSummaryView/Utils";
import {PlayerContext} from "../../player/PlayerContext";
import {ApplicationActions} from "../../player/ApplicationActions";

export const DebriefDriverContext = createContext();

const DebriefDriverContextProvider = (props) => {
    const initState = {
        isDriverDebriefWorkflow: false,
        transporter: {},
        searchTransporterList: {},
        debriefDetails: {},
        requestCompleted: false,
        isLoading: false,
        isDebriefComplete: false,
        isResolvingPackages: false,
        driverSummary: {},
        unresolvedPackages: [],
        currentPackageIndex: 0,
        unresolvedPackagesIndex: 0,
        packagesCount: 0,
        displayProgressBar: false
    };
    const [state, dispatch] = useReducer(DebriefDriverReducer, initState);
    const {dispatchPlayer} = useContext(PlayerContext);

    const debriefDriverActions = {
        getTransporterByBadgeId: (badgeId, dispatcher, debriefGlobalDispatcher) => {
            dispatch({
                type: actionType.GET_DRIVER_BY_BADGE_ID,
                data: {
                    dispatcher: dispatcher,
                    badgeId: badgeId,
                    debriefGlobalDispatcher: debriefGlobalDispatcher
                }
            })
        },
        getTransporterByBadgeIdResponse: (responseData) => {
            dispatchPlayer({type: ApplicationActions.DRIVER_ID_SCAN, data: {error: false}});
            dispatch({type: actionType.GET_DRIVER_BY_BADGE_ID_RESPONSE, data: responseData})
        },
        getTransporterByBadgeIdErrorResponse: () => {
            dispatchPlayer({type: ApplicationActions.DRIVER_ID_SCAN, data: {error: true}});
        },
        searchDriver: (keyword, dispatcher, debriefGlobalDispatcher) => {
            dispatch({
                type: actionType.SEARCH_DRIVER,
                data: {
                    dispatcher: dispatcher,
                    keyword: keyword,
                    debriefGlobalDispatcher: debriefGlobalDispatcher
                }
            })
        },
        searchDriverResponse: (responseData) => {
            dispatch({type: actionType.SEARCH_DRIVER_RESPONSE, data: responseData})
        },
        getDebriefDetails: (dispatcher, debriefGlobalDispatcher) => {
            dispatch({
                type: actionType.GET_DEBRIEF_DETAILS,
                data: {
                    dispatcher: dispatcher,
                    debriefGlobalDispatcher: debriefGlobalDispatcher
                }
            })
        },
        getDebriefDetailsResponse: (responseData) => {
            dispatch({type: actionType.GET_DEBRIEF_DETAILS_RESPONSE, data: responseData})
        },
        bulkReceivePackage: (requestData, dispatcher, debriefGlobalDispatcher) => {
            dispatch({
                type: actionType.BULK_RECEIVE_PACKAGE,
                data: {
                    dispatcher: dispatcher,
                    debriefGlobalDispatcher: debriefGlobalDispatcher,
                    requestData: requestData
                }
            })
        },
        bulkReceivePackageResponse: (responseData) => {
            dispatch({type: actionType.BULK_RECEIVE_PACKAGE_RESPONSE, data: responseData})
        },
        isLoading: () => {
            dispatch({type: actionType.LOADING})
        },
        loadingComplete: () => {
            dispatch({type: actionType.LOADING_COMPLETE})
        },
        setTransporter: (transporter) => {
            dispatch({type: actionType.SET_TRANSPORTER, data: transporter})
        },
        setDriverSummary: (driverSummary) => {
            dispatch({type: actionType.SET_DRIVER_SUMMARY, data: driverSummary})
            const {current, total} = driverReturnProgress(driverSummary)
            dispatch({type: actionType.SET_DRIVER_PROGRESS, data: {current: current, total: total}})
        },
        addToDriverSummary: (driverSummary) => {
            dispatch({type: actionType.SET_DRIVER_SUMMARY, data: driverSummary})
        },
        resetRequest: () => {
            dispatch({type: actionType.RESET_REQUEST})
        },
        activateDriverDebriefWorkflow: () => {
            dispatch({type: actionType.ACTIVATE_DRIVER_DEBRIEF_WORKFLOW})
        },
        setUnresolvedPackages: () => {
            const unresolvedPackages = getUnresolvedPackages(state.driverSummary)
            dispatch({type: actionType.SET_UNRESOLVED_PACKAGE, data: unresolvedPackages})
        },
        setDebriefComplete: () => {
            dispatch({type: actionType.SET_DEBRIEF_COMPLETE})
        },
        setResolvingPackages: () => {
            dispatch({type: actionType.SET_RESOLVING_PACKAGES})
        },
        resetResolvingPackages: () => {
            dispatch({type: actionType.RESET_RESOLVING_PACKAGES})
        },
        displayProgressBar: () => {
            dispatch({type: actionType.DISPLAY_PROGRESS_BAR})
        },
        resetDebriefDriverContext: () => {
            dispatch({type: actionType.RESET_STATE})
        }
    }

    return (
        <DebriefDriverContext.Provider value={{state, debriefDriverActions}}>
            {props.children}
        </DebriefDriverContext.Provider>
    )
}

export default DebriefDriverContextProvider;
