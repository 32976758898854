import React, {useContext, useEffect} from "react"
import Column from "@amzn/meridian/column";
import {DetailsCardView, HeaderView, Loader} from "@amzn/dolphin-uiweb-framework";
import NotificationView from "../../appContext/views/NotificationView";
import {FormattedMessage, injectIntl} from "react-intl";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {DebriefDriverContext} from "../context/DebriefDriverContext";
import {AppContext} from "../../appContext/context/AppContext";
import {navigate} from "../../constants/routes";
import {reconciliationStatusToTrackingIdMapUtil} from "./DriverSummaryView/Utils";
import {ApplicationActions} from "../../player/ApplicationActions";
import {PlayerConstants} from "../../player/PlayerConstant";
import {PlayerContext} from "../../player/PlayerContext";

const DriverConfirmView = (props) => {
    const {
        state: {
            isLoading,
            debriefDetails: {onRoadBuckets} = {},
            requestCompleted,
            transporter = {}
        }, debriefDriverActions
    } = useContext(DebriefDriverContext);
    const {appActions} = useContext(AppContext);
    const {dispatchPlayer} = useContext(PlayerContext)

    const handleConfirmIdentityClick = () => {
        dispatchPlayer({type: ApplicationActions.DRIVER_ID_CONFIRM, data: {option: PlayerConstants.CONFIRM_DRIVER}});
        debriefDriverActions.getDebriefDetails(debriefDriverActions, appActions.setNotification)
    };

    const handleSelectAnotherDriverClick = () => {
        dispatchPlayer({type: ApplicationActions.DRIVER_ID_CONFIRM, data: {option: PlayerConstants.SELECT_ANOTHER_DRIVER}});
        props.history.goBack();
    };

    useEffect(() => {
        if (requestCompleted) {
            if (onRoadBuckets) {
                debriefDriverActions.setDriverSummary(reconciliationStatusToTrackingIdMapUtil(onRoadBuckets));
                props.history.push(navigate.DRIVER_SUMMARY);
            }
        }
    }, [requestCompleted]);

    return (
        <Column height="100%" heights="fill">
            <Column spacingInset="medium">
                {isLoading && <Loader/>}
                <NotificationView/>
                <HeaderView primaryTitle={{title: props.intl.formatMessage({id: "confirm_returning_driver"})}}
                            secondaryTitle={{
                                title: props.intl.formatMessage({id: "confirm_driver"}),
                                size: "Medium",
                                type: "Bold"
                            }}/>
                <DetailsCardView snapshots={[{title: transporter.name, description: transporter.email}]}/>
            </Column>
            <Column alignmentHorizontal="center" alignmentVertical="bottom" spacingInset="medium">
                <Row width="100%" widths="fill" alignmentVertical="bottom">
                    <Button size="large" onClick={handleConfirmIdentityClick} type="primary">
                        <FormattedMessage id={"confirm_identity"}/>
                    </Button>
                </Row>
                <Row width="100%" widths="fill" alignmentVertical="bottom">
                    <Button size="large" onClick={handleSelectAnotherDriverClick} type="secondary">
                        <FormattedMessage id={"select_another_driver"}/>
                    </Button>
                </Row>
            </Column>
        </Column>
    )
};
export default injectIntl(DriverConfirmView);