import {axiosPOST} from "../../utils/Network/NetworkInterface";
import {driftEndpoints} from "../../constants/endpoints";
import {METRICS} from "../../constants/Metrics";
import {Modules} from "../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";

export async function receiveContainer(dispatcher, debriefGlobalDispatcher, trackingId) {
    let startTime = Date.now()
    dispatcher.isLoading()
    // TODO: format requestData
    const data = {
            scannableId: trackingId,
    }

    const response = await axiosPOST(METRICS.RECEIVE_CONTAINER, driftEndpoints.RECEIVE_CONTAINER,
        data, dispatcher, debriefGlobalDispatcher)

    if (response.status === 200) {
        MobileAnalyticsHelper.executeAPIAnalytics(Modules.RECEIVE_CONTAINER, startTime);
        dispatcher.fetchContainerResponse(response.data)
        return;
    }
    MobileAnalyticsHelper.executeAPIAnalytics(Modules.RECEIVE_CONTAINER, startTime, true);
}