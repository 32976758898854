import React, {useContext, useEffect, useState} from "react"
import Column from "@amzn/meridian/column";
import {ScanWithCodeView, DetailsCardView, HeaderView, Loader} from "@amzn/dolphin-uiweb-framework";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import {ReceivePackageContext} from "../../../context/ReceivePackageContext";
import NotificationView from "../../../../appContext/views/NotificationView";
import {DebriefDriverContext} from "../../../../debriefDriver/context/DebriefDriverContext";
import {ValidationUtils} from "../../../../utils/Utils";
import {AppContext} from "../../../../appContext/context/AppContext";
import DialogHead from "../../../../common/DialogBox";
import PackageDetailsView from "../PackageDetailsView";
import {navigate} from "../../../../constants/routes";
import {createPackageSnapshot} from "../PackageDetailsView/Constants";
import {FormattedMessage, injectIntl} from "react-intl";
import {NotificationType} from "../../../../constants/constants";
import {addPackageToSummary, getErrorMessage} from "../ScanPackageView/Utils";
import {ResponseCode} from "../ScanPackageView/Constants";
import {ReconciliationStatus} from "../../../../debriefDriver/views/DriverSummaryView/Constants";
import {interceptBackButton, interceptBackButtonStop} from "../../../../Handler/BackPressHandler";
import {PlayerContext} from "../../../../player/PlayerContext";
import {ApplicationActions} from "../../../../player/ApplicationActions";
import {HelpOption} from "../../../../player/PlayerConstant";
import {FeatureManager} from "@amzn/dolphin-web-framework";
import {getPackageStatusText, MoveToProblemSolveStatuses} from "./Utils";

const PackageReturnedView = (props) => {
    const {state: {packageAttributes = {},
        isLoading = false,
        requestCompleted = false,
        packageData : {
            packageQualifier: {scannableId = ""} = {},
            userOutput,
            userOutput: {
                responseStatus = "",
                responseCode = ""} = {},
            userOutput: {
                displayOutput: {
                    currentStatus: packageState = "", tagList = []} = {}
            } = {}} = {}}, receivePackageActions} = useContext(ReceivePackageContext);
    const {state: {transporter, isDriverDebriefWorkflow, packagesCount = 0, driverSummary = {}}, debriefDriverActions} = useContext(DebriefDriverContext);
    const {appActions} = useContext(AppContext);
    const {dispatchPlayer} = useContext(PlayerContext);
    const [packageStatus, setPackageStatus] = useState("");
    const [snapshots, setSnapshots] = useState([])
    const [displayPackageDetailsDialog, setDisplayPackageDetailsDialog] = useState(false)

    const onPackageScan = (trackingId) => {
        if(trackingId.trim() !== "") {
            receivePackageActions.resetRequest()
            receivePackageActions.receivePackage(trackingId.trim(), transporter.transporterId, receivePackageActions, appActions.setNotification)
        }
    }

    useEffect(() => {
        if (ValidationUtils.isNotEmptyObject(packageAttributes)) {
            const isScheduledDeliveryDisplayEnabled = FeatureManager.isFeatureEnabled(FeatureManager.Features.SCHEDULED_DELIVERY_DISPLAY_ENABLED);
            setSnapshots(createPackageSnapshot(props.intl, packageAttributes, isScheduledDeliveryDisplayEnabled))
            setPackageStatus(packageState)
        }
        appActions.setHelpOptions([
            {title: props.intl.formatMessage({id: "view_package_details"}), onClick: displayPackageDetailsDialogOption},
            {title: props.intl.formatMessage({id: "override_package_status"}), onClick: overridePackageStatus},
        ])
        return () => {
            appActions.resetHelpOptions()
        }
    }, [packageAttributes])

    const onBackPressed = () => {
        appActions.openBackDrop()
    }

    useEffect(() => {
        appActions.setBackPress(onBackPressed)
        interceptBackButton();
        return () => {
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])

    useEffect(() => {
        if (requestCompleted) {
            receivePackageActions.resetRequest()
            if (ValidationUtils.isNotEmptyObject(userOutput)) {
                receivePackageActions.setPackageAttributes(userOutput.displayOutput.packageAttributes)
                let trackingId;
                if(userOutput.displayOutput?.packageAttributes?.scannableId) {
                    trackingId = userOutput.displayOutput.packageAttributes.scannableId;
                } else {
                    trackingId = scannableId;
                }
                const slicedTrackingId = trackingId.slice(-4);
                if (responseStatus === "ERROR") {
                    appActions.setNotification(NotificationType.ERROR,
                        getErrorMessage(props.intl, slicedTrackingId, responseCode, tagList))
                    if(FeatureManager.isFeatureEnabled(FeatureManager.Features.RTS_FIXIT_INTEGRATION)
                        && tagList && tagList.length > 0 && MoveToProblemSolveStatuses.includes(tagList[0].shipmentTag)){
                        props.history.push(navigate.RETURNED_PACKAGE);
                    } else if(responseCode !== ResponseCode.INVALID_TRACKING_ID) {
                        props.history.push(navigate.PACKAGE_OVERVIEW);
                    }
                } else {
                    const message = <FormattedMessage id="return_success_message" values={{
                        trackingId: slicedTrackingId
                    }}/>
                    appActions.setNotification(responseStatus.toLowerCase(), message)
                    debriefDriverActions.setDriverSummary(addPackageToSummary(trackingId, driverSummary, ReconciliationStatus.RETURNED))
                }
            }
        }

    }, [requestCompleted]);


    const all_packages_scanned = () => {
        if (packagesCount > 0) {
            debriefDriverActions.setUnresolvedPackages();
            props.history.push(navigate.RESOLVE_PACKAGES);
        } else {
            dispatchPlayer({type: ApplicationActions.ALL_PACKAGE_RETURNED, data: {operation: ApplicationActions.DEBRIEF_COMPLETE}});
            props.history.push(navigate.RETURN_COMPLETE)
        }
    }

    const overridePackageStatus = () => {
        appActions.disableHelp()
        dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HelpOption.OVERRIDE_PACKAGE_STATUS}})
        props.history.push(navigate.PACKAGE_OVERVIEW)
    }

    const displayPackageDetailsDialogOption = () => {
        dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HelpOption.VIEW_PACKAGE_DETAILS}})
        setDisplayPackageDetailsDialog(true)
    }

    const closePackageDetailsDialogOption = () => {
        appActions.disableHelp()
        setDisplayPackageDetailsDialog(false)
    }

    const packageDetailsDialog = () => {
        return (
            <DialogHead onClose={closePackageDetailsDialogOption}>
                <PackageDetailsView/>
            </DialogHead>
        )
    }

    return (
        <Column height="100%" heights="fill" spacingInset="medium">
            {displayPackageDetailsDialog && packageDetailsDialog()}
            {isLoading && <Loader />}
            <div>
                <NotificationView/>
                <Column spacingInset="small" alignmentHorizontal="center">
                    <HeaderView primaryTitle={{title: getPackageStatusText(packageStatus, packageAttributes, tagList)}}
                                secondaryTitle={{title: props.intl.formatMessage({id:"scan_next_package_title_message"}), size: "Medium", type: "Bold"}}/>
                </Column>
                <Column width="100%" widths="fill">
                    <Row alignmentHorizontal="center">
                        <Text type="b300"><FormattedMessage id="stow_package_instructions" /></Text>
                    </Row>
                    <DetailsCardView snapshots={snapshots}/>
                </Column>
            </div>
            <ScanWithCodeView onSubmit={onPackageScan} focusLock={true}/>
            {isDriverDebriefWorkflow &&
            <Column alignmentHorizontal="center" alignmentVertical="bottom">
                <Row width="100%" widths="fill" alignmentVertical="bottom">
                    <Button onClick={all_packages_scanned} size="large" type="secondary"><FormattedMessage id="all_packages_returned" /></Button>
                </Row>
            </Column>
            }
        </Column>
    )
}


export default injectIntl(PackageReturnedView);
