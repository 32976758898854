import {Utils} from "../../../../utils/Utils";

const FutureDeliveryStatuses = [
    "HOLD_FOR_REDELIVERY",
    "DELAYED_AT_STATION",
    "HOLD_FOR_ACTION",
    "HOLD_FOR_FUTURE_REDELIVERY"
]
export const MoveToProblemSolveStatuses = [
    "DAMAGED",
    "PRINT_LABEL"
]
const SAPERATOR = " - "
const TODAY = "today"
const PROBLEM_SOLVE = "Sideline for problem solve"

export const getPackageStatusText = (status, packageAttributes, tagList) => {
    if (tagList && tagList.length > 0) {
        if (MoveToProblemSolveStatuses.includes(tagList[0].shipmentTag)) {
            return PROBLEM_SOLVE
        }
    }
    let elaboratedPackageStatusText = status
    if(FutureDeliveryStatuses.includes(status)) {
        const currentDateFormat = new Date()
        const currentDate = Utils.convertMillisToDate(currentDateFormat.getTime(), "DD-MMM")
        const promisedDate = Utils.convertMillisToDate(packageAttributes.promiseDeliveryTime, "DD-MMM")
        elaboratedPackageStatusText = elaboratedPackageStatusText.concat(SAPERATOR)
        if(currentDate === promisedDate) {
            elaboratedPackageStatusText = elaboratedPackageStatusText.concat(TODAY)
        } else {
            elaboratedPackageStatusText = elaboratedPackageStatusText.concat(promisedDate)
        }
    }
    return elaboratedPackageStatusText;
}