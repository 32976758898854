import {actionType} from "../../constants/actionType";
import {
    bulkTranferPackages,
    fetchTransporterByBadgeId,
    getDebriefDetails,
    searchTransporterBykeyWord
} from "../network/DebriefDriverNetworkAPI";

export const DebriefDriverReducer = (state, action) => {
    let dispatcher, keyword, requestData, debriefGlobalDispatcher, badgeId
    switch (action.type) {
        case actionType.GET_DRIVER_BY_BADGE_ID:
            ({dispatcher, debriefGlobalDispatcher, badgeId} = action.data)
            fetchTransporterByBadgeId(dispatcher, debriefGlobalDispatcher, badgeId);
            return {...state, requestCompleted: false};

        case actionType.GET_DRIVER_BY_BADGE_ID_RESPONSE:
            return {...state, transporter: action.data, isLoading: false, requestCompleted: true};

        case actionType.SEARCH_DRIVER:
            ({dispatcher, debriefGlobalDispatcher, keyword} = action.data)
            searchTransporterBykeyWord(dispatcher, debriefGlobalDispatcher, keyword);
            return {...state, requestCompleted: false};

        case actionType.SEARCH_DRIVER_RESPONSE:
            return {...state, searchTransporterList: action.data};

        case actionType.GET_DEBRIEF_DETAILS:
            ({dispatcher, debriefGlobalDispatcher} = action.data)
            getDebriefDetails(dispatcher, debriefGlobalDispatcher, state.transporter);
            return {...state, debriefDetails: {}, requestCompleted: false};

        case actionType.GET_DEBRIEF_DETAILS_RESPONSE:
            return {...state, isLoading: false, debriefDetails: action.data, requestCompleted: true};

        case actionType.BULK_RECEIVE_PACKAGE:
            ({dispatcher, debriefGlobalDispatcher, requestData} = action.data)
            bulkTranferPackages(dispatcher, debriefGlobalDispatcher, requestData);
            return {...state, debriefDetails: {}, requestCompleted: false};

        case actionType.BULK_RECEIVE_PACKAGE_RESPONSE:
            return {...state, isLoading: false, requestCompleted: true};

        case actionType.LOADING:
            return {...state, isLoading: true};

        case actionType.LOADING_COMPLETE:
            return {...state, isLoading: false};

        case actionType.SET_TRANSPORTER:
            return {...state, transporter: action.data}

        case actionType.SET_DRIVER_SUMMARY:
            return {...state, driverSummary: action.data}

        case actionType.RESET_REQUEST:
            return {...state, requestCompleted: false}

        case actionType.ACTIVATE_DRIVER_DEBRIEF_WORKFLOW:
            return {...state, isDriverDebriefWorkflow: true}

        case actionType.SET_UNRESOLVED_PACKAGE:
            return {...state, unresolvedPackages: action.data}

        case actionType.SET_DEBRIEF_COMPLETE:
            return {...state, isDebriefComplete: true}

        case actionType.SET_RESOLVING_PACKAGES:
            return {...state, unresolvedPackagesIndex:0, isResolvingPackages: true}

        case actionType.RESET_RESOLVING_PACKAGES:
            return {...state, isResolvingPackages: false}

        case actionType.SET_DRIVER_PROGRESS:
            return {...state, currentPackageIndex: action.data.current, packagesCount: action.data.total}

        case actionType.DISPLAY_PROGRESS_BAR:
            return {...state, displayProgressBar: true}

        case actionType.RESET_STATE:
            return {
                isDriverDebriefWorkflow: false,
                transporter: {},
                searchTransporterList: {},
                debriefDetails: {},
                requestCompleted: false,
                isLoading: false,
                isDebriefComplete: false,
                isResolvingPackages: false,
                driverSummary: {},
                unresolvedPackages: [],
                currentPackageIndex: 0,
                packagesCount: 0,
                displayProgressBar: false
            }

        default:
            return state
    }
}