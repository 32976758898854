import moment from "moment"

const DATE_FORMAT = "DD-MMM-YYYY HH:mm:ss";

export const MODULE_NAME = 'ReturnToStation'

//training status
export const TRAINING_STATUS = {
    EXPIRED: 'EXPIRED'
}

export const Utils = {
    convertMillisToDate: (millis, dateFormat = DATE_FORMAT) => {
        if(millis) {
            let date = new Date(millis);
            date = new Date(date.toLocaleString('en-US'));
            return moment(date).format(dateFormat)
        }
    }
}

export const ValidationUtils = {
    isNotEmptyObject: (obj) => {
        return Boolean(obj && Object.keys(obj).length > 0)
    },

    isNotEmptyArray: (obj) => {
        return Boolean(obj && obj.length > 0)
    }
}
