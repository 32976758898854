export const primary = "primary";
export const secondary = "secondary";
export const POST = "post";
export const GET = "get";

export const THEME_REGULAR_NAV = "#252829"
export const PROGRESS_COLOR = "#00A8E1"
export const DEFAULT = "default"
export const ERROR = "error"

export const CATEGORY_ONROAD = "onRoadPkgStatusReasonMapping";
export const CATEGORY_INSTATION = "inStationPkgStatusReasonMapping";

export const NotificationType = {
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error"
}