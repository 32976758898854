const BACK_PRESSED = "BACK_PRESSED";

let prevBackPressedListener = undefined;
let interceptBackButtonReason = undefined;
let loadTime = undefined
const MIN_LOAD_TIME = 1500

window.addEventListener(BACK_PRESSED, function (event) {
    prevBackPressedListener && prevBackPressedListener(event);
});

window.addEventListener('popstate', function (event) {
    if (interceptBackButtonReason && interceptBackButtonReason === window.location.href) {
        // if loadTime is < MIN_LOAD_TIME, then ignore popstate event
        const timeDiff = new Date().getTime() - loadTime
        if(timeDiff > MIN_LOAD_TIME){
            window.dispatchEvent(new Event("BACK_PRESSED"));
        }
        window.history.pushState({noBack: true}, '')
    }
});

export function setBackPressedEventListener(listener) {
    prevBackPressedListener = listener;
}

export function clearBackPressedEventListener() {
    prevBackPressedListener = undefined;
}

export function interceptBackButton() {
    if(!loadTime) {
        loadTime = new Date().getTime()
    }
    interceptBackButtonReason = window.location.href;
    window.history.pushState({noBack: true}, '');
}

export function interceptBackButtonStop() {
    interceptBackButtonReason = undefined;
}