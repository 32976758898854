import {NotificationType} from "../../constants/constants";
import {ReceiveContainerResponseCode, ResponseStatus} from "./Constants";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {Modules, Events} from "../../constants/AnalyticsEventKeys";

export const getErrorMessageForReceiveContainer = (intl, trackingId, responseCode, containerType, sourceName) => {
    let message
    switch (responseCode) {
        case ReceiveContainerResponseCode.AT_WRONG_STATION:
            message = intl.formatMessage({id: "receive_at_wrong_station_message"}, {
                containerType: containerType,
                trackingId: trackingId,
                sourceName: sourceName
            })
            break;
        case ReceiveContainerResponseCode.NO_PATH_PLAN:
            message = intl.formatMessage({id: "receive_no_path_plan_message"}, {
                responseCode: responseCode,
                trackingId: trackingId,
            })
            break;
        case ReceiveContainerResponseCode.UNEXPECTED_STATUS:
            message = intl.formatMessage({id: "unexpected_status_message"}, {
                trackingId: trackingId,
            })
            break;
        default:
            message = intl.formatMessage({id: "receive_error_message"}, {
                containerType: containerType,
                trackingId: trackingId,
            })
    }
    return message
}

export const resolveNotificationUtil = (intl, scannableId, responseCode, responseStatus, containerType, sourceName) => {
    let startTime = Date.now();
    const trackingId = scannableId.slice(-4)
    const Notification = {
        notificationType: "",
        message: ""
    }
    let description1 = ""
    if (ReceiveContainerResponseCode.INVALID_TRACKING_ID === responseCode) {
        Notification.notificationType = NotificationType.ERROR
        Notification.message = intl.formatMessage({id: "invalid_trackingId_message"})
        description1 = Modules.INVALID_TRACKING_ID
    } else if (ReceiveContainerResponseCode.ALREADY_PROCESSED === responseCode) {
        Notification.notificationType = NotificationType.SUCCESS
        Notification.message = intl.formatMessage({id: "already_processed_message"}, {
            trackingId: trackingId,
        })
        description1 = Modules.ALREADY_PROCESSED
    } else {
        switch (responseStatus) {
            case ResponseStatus.ERROR:
                Notification.notificationType = NotificationType.ERROR
                Notification.message = getErrorMessageForReceiveContainer(intl, trackingId, responseCode, containerType, sourceName)
                description1 = Modules.RECEIVE_FAILED
                break;
            case ResponseStatus.WARNING:
                Notification.notificationType = NotificationType.WARNING
                Notification.message = intl.formatMessage({id: "receive_warning_message"}, {
                    containerType: containerType,
                    trackingId: trackingId,
                })
                description1 = Modules.RECEIVED_WITH_WARNING
                break;
            case ResponseStatus.SUCCESS:
                Notification.notificationType = NotificationType.SUCCESS
                Notification.message = intl.formatMessage({id: "receive_success_message"}, {
                    containerType: containerType,
                    trackingId: trackingId,
                })
                description1 = Modules.RECIEVED_SUCCESSFULLY
                break;
            default:
                break;
        }
    }
    MobileAnalyticsHelper.processAnalytics(startTime, Modules.RECEIVE_CONTAINER, "", Modules.DEBRIEF,
        scannableId, description1 , Events.USER_SCANNED_CONTAINER);
    return Notification
}