import React, {useContext, useEffect, useState} from "react"
import Column from "@amzn/meridian/column";
import NotificationView from "../../../../appContext/views/NotificationView";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Input from "@amzn/meridian/input";
import {AppContext} from "../../../../appContext/context/AppContext";
import {ReceivePackageContext} from "../../../context/ReceivePackageContext";
import {DebriefDriverContext} from "../../../../debriefDriver/context/DebriefDriverContext";
import {navigate} from "../../../../constants/routes";
import {processOverrideStatusResponse} from "../SelectStatusView/Constants";
import {FormattedMessage, injectIntl} from "react-intl";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import {PlayerContext} from "../../../../player/PlayerContext";
import {ApplicationActions} from "../../../../player/ApplicationActions";
import {StatusOverrideOption} from "../../../../player/PlayerConstant";

const RecipientInformationView = (props) => {

    const {
        state: {
            overrideCategory: selectedCategory,
            updateStatusData: {targetStatus = ""} = {},
            isLoading, packageAttributes: {scannableId = ""} = {},
            requestCompleted, updateSuccess
        }, receivePackageActions
    } = useContext(ReceivePackageContext);

    const {
        state: {isResolvingPackages, packagesCount, currentPackageIndex = 0, driverSummary = {}},
        debriefDriverActions
    } = useContext(DebriefDriverContext);

    const {appActions} = useContext(AppContext);
    const [recipientName, setRecipientName] = useState("")
    const [recipientAddress, setRecipientAddress] = useState("")
    const {dispatchPlayer} = useContext(PlayerContext);

    const cancel = () => {
        dispatchPlayer({type: ApplicationActions.STATUS_OVERRIDE, data:{ operation: StatusOverrideOption.CANCEL}})
        props.history.push(navigate.PACKAGE_OVERVIEW)
    }

    const updateStatus = () => {
        const targetStatusData = {
            deliveredToNeighbor: true,
            recipientDoorNumber: recipientAddress,
            recipientName: recipientName,
        }
        receivePackageActions.updateStatusData(targetStatusData)
        receivePackageActions.updateTargetStatus(receivePackageActions, appActions.setNotification)
    }

    useEffect(() => {
        if (requestCompleted) {
            receivePackageActions.resetRequest()
            processOverrideStatusResponse(updateSuccess, appActions, scannableId, targetStatus, selectedCategory, driverSummary,
                isResolvingPackages, packagesCount, currentPackageIndex, debriefDriverActions, props, dispatchPlayer)
        }
    }, [requestCompleted])

    const displayRegularComponent = () => {
        return (
            <Column height="100%" heights="fill">
                <Column spacingInset="medium" spacing="450">
                    <NotificationView/>
                    <Column>
                        <Text type="h100"><FormattedMessage id="fill_recipient_information" /></Text>
                    </Column>
                    <Column spacing="200">
                        <Text type="b200"><FormattedMessage id="package_delivered_to_title" /></Text>
                        <Input value={recipientName} onChange={setRecipientName} type="text" autoFill={false}
                               placeholder={props.intl.formatMessage({id:"delivered_to"})}/>
                    </Column>
                    <Column spacing="200">
                        <Text type="b200"><FormattedMessage id="door_number_title" /></Text>
                        <Input value={recipientAddress} onChange={setRecipientAddress} type="text" autoFill={false}
                               placeholder={props.intl.formatMessage({id:"door_number"})}/>
                    </Column>
                </Column>

                <Column alignmentHorizontal="center" alignmentVertical="bottom" spacingInset="medium">
                    <Row width="100%" widths="fill" alignmentVertical="bottom">
                        <Button onClick={updateStatus} size="large" type="primary">
                            <FormattedMessage id="btn_update_status_submit" />
                        </Button>
                    </Row>
                    <Row width="100%" widths="fill" alignmentVertical="bottom">
                        <Button onClick={cancel} size="large" type="secondary">
                            <FormattedMessage id="btn_cancel" />
                        </Button>
                    </Row>
                </Column>
            </Column>
        )
    }

    return (
        <Column height="100%" heights="fill">
            {isLoading && <Loader/>}
            {displayRegularComponent()}
        </Column>
    )
}

export default injectIntl(RecipientInformationView);
