import React, {useContext, useEffect, useState} from 'react';
import Column from "@amzn/meridian/column";
import {ReceivePackageContext} from "../../../context/ReceivePackageContext";
import {Loader, ScanWithCodeView, ScanWithInputView} from "@amzn/dolphin-uiweb-framework";
import NotificationView from "../../../../appContext/views/NotificationView";
import {DebriefDriverContext} from "../../../../debriefDriver/context/DebriefDriverContext";
import {AppContext} from "../../../../appContext/context/AppContext";
import {ValidationUtils} from "../../../../utils/Utils";
import {addPackageToSummary, getErrorMessage, } from "./Utils";
import {NotificationType} from "../../../../constants/constants";
import DialogHead from "../../../../common/DialogBox";
import {ReconciliationStatus} from "../../../../debriefDriver/views/DriverSummaryView/Constants";
import {navigate} from "../../../../constants/routes";
import {ResponseCode} from "./Constants";
import {FormattedMessage, injectIntl} from "react-intl";
import {interceptBackButton, interceptBackButtonStop} from "../../../../Handler/BackPressHandler";
import image from "../../../../../images/ic_package_scan.png";
import {FeatureManager} from "@amzn/dolphin-web-framework";
import {PlayerContext} from "../../../../player/PlayerContext";
import {ApplicationActions} from "../../../../player/ApplicationActions";
import {HelpOption, PlayerConstants} from "../../../../player/PlayerConstant";
import {MoveToProblemSolveStatuses} from "../../package/PackageReturnedView/Utils"

const ScanPackageView = (props) => {
    const {
        state: {
            packageData: {
                packageQualifier: {scannableId = ""} = {},
                userOutput, userOutput: {responseStatus = "", responseCode = ""} = {},
                userOutput: {displayOutput: {packageAttributes = {}, tagList = []} = {}} = {}
            } = {},
            requestCompleted, isLoading
        }, receivePackageActions
    } = useContext(ReceivePackageContext);
    const {state: {transporter, isDriverDebriefWorkflow, packagesCount = 0, driverSummary = {}}, debriefDriverActions} = useContext(DebriefDriverContext);

    const {appActions} = useContext(AppContext);
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);
    const [displayHelpDialog, setDisplayHelpDialog] = useState(false)

    const onPackageScan = (trackingId) => {
        if(trackingId !== "") {
            receivePackageActions.resetRequest()
            receivePackageActions.receivePackage(trackingId, transporter.transporterId, receivePackageActions, appActions.setNotification)
        }
    }

    const all_packages_scanned = () => {
        if (packagesCount > 0) {
            debriefDriverActions.setUnresolvedPackages()
            props.history.push(navigate.RESOLVE_PACKAGES);
        } else {
            dispatchPlayer({type: ApplicationActions.ALL_PACKAGE_RETURNED, data: {operation: ApplicationActions.DEBRIEF_COMPLETE}});
            props.history.push(navigate.RETURN_COMPLETE)
        }
    }

    const button = {
        title: <FormattedMessage id="all_packages_returned" />,
        onClick: all_packages_scanned
    }

    const initialState = {
        primaryTitle: {
            title: <FormattedMessage id="scan_package_label" />,
            type: "Bold"
        },
        image: image,
    }

    useEffect(() => {
        if (requestCompleted) {
            receivePackageActions.resetRequest()
            if (ValidationUtils.isNotEmptyObject(userOutput)) {
                receivePackageActions.setPackageAttributes(packageAttributes)
                let trackingId;
                if(userOutput.displayOutput?.packageAttributes?.scannableId) {
                    trackingId = packageAttributes.scannableId;
                } else {
                    trackingId = scannableId;
                }
                const slicedTrackingId = trackingId.slice(-4)
                if (responseStatus === "ERROR") {
                    appActions.setNotification(NotificationType.ERROR,
                        getErrorMessage(props.intl, slicedTrackingId, responseCode, tagList))
                    if(FeatureManager.isFeatureEnabled(FeatureManager.Features.RTS_FIXIT_INTEGRATION)
                        && tagList && tagList.length > 0 && MoveToProblemSolveStatuses.includes(tagList[0].shipmentTag)){
                        props.history.push(navigate.RETURNED_PACKAGE);
                    } else if(responseCode !== ResponseCode.INVALID_TRACKING_ID) {
                        props.history.push(navigate.PACKAGE_OVERVIEW);
                    }
                } else {
                    const message = <FormattedMessage id="return_success_message" values={{
                        trackingId: slicedTrackingId
                    }}/>
                    appActions.setNotification(responseStatus.toLowerCase(), message)
                    debriefDriverActions.setDriverSummary(addPackageToSummary(trackingId, driverSummary, ReconciliationStatus.RETURNED))
                    props.history.push(navigate.RETURNED_PACKAGE);
                }
            }
        }
    }, [requestCompleted]);

    const onBackPressed = () => {
        appActions.openBackDrop()
    }

    useEffect(() => {
        dispatchPlayer({type: ApplicationActions.TASK_SELECTED, data: {taskId: PlayerConstants.DEBRIEF_PACKAGES}});
        if(FeatureManager.isFeatureEnabled(FeatureManager.Features.MANUAL_TRACKING_ID_HELP_ENABLED)) {
            appActions.setHelpOptions([
                {title: props.intl.formatMessage({id: "manually_enter_package_id"}), onClick: displayManualInputHelp}
            ])
        }
        appActions.setBackPress(onBackPressed)
        interceptBackButton();
        return () => {
            appActions.setHelpOptions([])
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])

    const displayManualInputHelp = () => {
        dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HelpOption.MANUAL_SCAN}})
        setDisplayHelpDialog(true)
    }

    const closeManualInputDialog = () => {
        appActions.disableHelp()
        setDisplayHelpDialog(false)
    }

    const manualTrackingIdSubmit = (trackingId) => {
        closeManualInputDialog()
        statePlayer.isManual = true;
        onPackageScan(trackingId)
    }

    const displayDefaultComponent = () => {
        return (
            <Column spacingInset="medium" height="100%">
                <NotificationView/>
                <ScanWithCodeView
                    {...initialState}
                    onSubmit={onPackageScan}
                    focusLock={!displayHelpDialog}
                    button={isDriverDebriefWorkflow ? {...button} : null} />
            </Column>
        )
    }

    const manuallyEnterTrackingId = () => {
        return (
            <DialogHead onClose={closeManualInputDialog}>
                <ScanWithInputView title={props.intl.formatMessage({id: "package_id"})}
                                   description={props.intl.formatMessage({id: "manually_enter_package_id"})}
                                   onSubmit={manualTrackingIdSubmit}
                                   autoFocus={true}/>
            </DialogHead>
        )
    }

    return (
        <Column height="100%" heights="fill">
            {isLoading && <Loader/>}
            {displayHelpDialog && manuallyEnterTrackingId()}
            {!displayHelpDialog && displayDefaultComponent()}
        </Column>
    )
}

export default injectIntl(ScanPackageView);
