import React, {createContext, useReducer, useContext} from "react";
import {AppReducer} from "../reducers/AppReducer";
import {actionType} from "../../constants/actionType";
import {DEFAULT} from "../../constants/constants";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {PlayerContext} from "../../player/PlayerContext";
import {ApplicationActions} from "../../player/ApplicationActions";
import {BackOption} from "../../player/PlayerConstant";


export const AppContext = createContext();

const AppContextProvider = (props) => {
    const {dispatchPlayer} = useContext(PlayerContext);
    const initState = {
        displayNotification: {},
        helpActive: false,
        helpOptions: [],
        navTheme: DEFAULT,
        onBackPressed: null,
        backOptions: {
            open: false,
            primaryButtonOnClick:  () => {
                dispatchPlayer({type: ApplicationActions.BACK_OPTION_SELECTED, data:{operation: BackOption.CONTINUE}})
                appActions.closeBackDrop()
            },
            secondaryButtonOnClick: () => {
                dispatchPlayer({type: ApplicationActions.BACK_OPTION_SELECTED, data:{operation: BackOption.EXIT}})
                appActions.closeBackDrop();
                NativeMeshInteractor.exitModule();
            }
        }
    };

    const [state, dispatch] = useReducer(AppReducer, initState);

    const appActions = {
        setNotification: (type, message) => {
            dispatch({type: actionType.SET_NOTIFICATION, data: {type: type, message: message}})
        },
        resetNotification: () => {
            dispatch({type: actionType.RESET_NOTIFICATION})
        },
        enableHelp: () => {
            dispatch({type: actionType.ENABLE_HELP})
        },
        disableHelp: () => {
            dispatch({type: actionType.DISABLE_HELP})
        },
        setHelpOptions: (helpOptions) => {
            dispatch({type: actionType.SET_HELP_OPTIONS, helpOptions: helpOptions})
        },
        resetHelpOptions: () => {
            dispatch({type: actionType.RESET_HELP_OPTIONS})
        },
        openBackDrop: () => {
            dispatch({type: actionType.OPEN_BACK_DROP});
        },
        closeBackDrop: () => {
            dispatch({type: actionType.CLOSE_BACK_DROP});
        },
        setBackPress: (option) => {
            dispatch({type: actionType.SET_BACK_PRESS, data: option});
        },
        resetBackPress: () => {
            dispatch({type: actionType.SET_BACK_PRESS, data: null});
        },
        setNavTheme: (type) => {
            dispatch({type: actionType.SET_NAV_THEME, data: type})
        },
        resetAppContext: () => {
            dispatch({type: actionType.RESET_STATE})
        }
    }

    return (
        <AppContext.Provider value={{state, appActions}}>
            {props.children}
        </AppContext.Provider>
    )
}

export default AppContextProvider;
