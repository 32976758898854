import React, {useContext, useCallback, useEffect} from "react";
import helpTokens from "@amzn/meridian-tokens/base/icon/help";
import {BackPressedView, HelpView, ProgressBarView, TopNavigationView} from "@amzn/dolphin-uiweb-framework"
import {AppContext} from "../context/AppContext";
import {DebriefDriverContext} from "../../debriefDriver/context/DebriefDriverContext";
import {ValidationUtils} from "../../utils/Utils";
import Column from "@amzn/meridian/column";
import ic_help from "../../../images/ic_help.png"
import {DEFAULT, PROGRESS_COLOR} from "../../constants/constants";
import {clearBackPressedEventListener, setBackPressedEventListener} from "../../Handler/BackPressHandler";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import {ApplicationActions} from "../../player/ApplicationActions";
import {PlayerContext} from "../../player/PlayerContext";
import {BackOption} from "../../player/PlayerConstant";

const TopNavigationBar = (props) => {
    const {state: {
        helpActive = false,
        helpOptions = [],
        navTheme = DEFAULT,
        onBackPressed,
        backOptions,
        } = {}, appActions} = useContext(AppContext)

    const {
        state: {
            driverSummary = {},
            packagesCount = 0,
            currentPackageIndex = 0,
            displayProgressBar = false
        } = {}
    } = useContext(DebriefDriverContext);
    const {dispatchPlayer} = useContext(PlayerContext);

    const checkIfDisplayProgressBar = () => {
        return (ValidationUtils.isNotEmptyObject(driverSummary) && packagesCount > 0) && displayProgressBar
    }

    const openHelp = () => {
        dispatchPlayer({type: ApplicationActions.HELP_SELECTED})
        appActions.enableHelp()
    }

    const closeHelp = () => {
        dispatchPlayer({type: ApplicationActions.HELP_CLOSED})
        appActions.disableHelp()
    }

    /**
     * The following code is listening to the back press events
     * The code is supposed to close any views like help or backdrop view
     * in case of either are not visible we call onBackPressed function from the appState
     * the default behaviour is to go back in history
     */
    const onBackPressedEvent = useCallback(() => {
        dispatchPlayer({type: ApplicationActions.BACK_SELECTED})
        // TODO: handle backpress for all screens for the help options to be closed
        if (helpActive) {
            closeHelp()
        } else if (backOptions.open) {
            appActions.closeBackDrop();
        } else if (onBackPressed) {
            onBackPressed.call();
        } else {
            props.history.goBack()
        }
    }, [onBackPressed, backOptions, helpActive]);

    useEffect(() => {
        setBackPressedEventListener(onBackPressedEvent);
        return clearBackPressedEventListener;
    }, [onBackPressed, backOptions, helpActive]);

    return (
        <Column spacing="none" id={"top-navigation"}>
            <TopNavigationView
                title={"Receive"}
                backgroundType={navTheme}
                onMenuButtonClicked={onBackPressedEvent}
                optionsMenu={[{icon: helpTokens, onClick: openHelp}]}/>
            {
                checkIfDisplayProgressBar() &&
                    <ProgressBarView primaryColor={PROGRESS_COLOR} currentCount={currentPackageIndex}
                             totalCount={packagesCount}/>
            }
            {
                helpActive &&
                    <HelpView onClose={closeHelp} title={"Help Center"} pageTitle={"Select help options"}
                      list={helpOptions} help_icon={ic_help}/>
            }
            {
                backOptions.open &&
                <BackPressedView
                    onClose={() => {dispatchPlayer({type: ApplicationActions.BACK_OPTION_SELECTED, data:{operation: BackOption.CLOSE}}); appActions.closeBackDrop();}}
                    title={props.intl.formatMessage({id: "back_button_pressed"})}
                    description={props.intl.formatMessage({id: "back_button_notification_message"})}
                    primaryButtonTitle={props.intl.formatMessage({id: "btn_continue_receive"})}
                    secondaryButtonTitle={props.intl.formatMessage({id: "btn_end_receive_and_exist"})}
                    primaryButtonOnClick={backOptions.primaryButtonOnClick}
                    secondaryButtonOnClick={backOptions.secondaryButtonOnClick}/>
            }
        </Column>

    )
}

export default withRouter(injectIntl(TopNavigationBar))
