import React, {Component} from "react";
import ReceiveContainerView from "./view";
import ReceiveContainerContextProvider from "./context/ReceiveContainerContext";

class ReceiveContainer extends Component {
    render() {
        return (
            <ReceiveContainerContextProvider>
                <ReceiveContainerView history={this.props.history}/>
            </ReceiveContainerContextProvider>
        );
    }
}

export default ReceiveContainer;