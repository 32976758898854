export const navigate = {
    HOME: "/",
    RECEIVE_CONTAINER: "/receiveContainer",
    SCAN_DRIVER_BY_BADGE_ID: "/scanDriverBadgeId",
    SEARCH_DRIVER_WITH_KEYWORD: "/searchDriverDetails",
    DRIVER_SUMMARY: "/driverSummary",
    DRIVER_CONFIRM: "/driverConfirm",
    RESOLVE_PACKAGES: "/resolvePackages",
    RETURN_COMPLETE: "/returnComplete",
    BULK_TRANSFER: "/bulkTransfer",
    BULK_TRANSFER_SUCCESS: "/bulkTransferSuccess",
    RECEIVE_PACKAGE: "/receivePackage",
    RETURNED_PACKAGE: "/returnedPackage",
    PACKAGE_OVERVIEW: "/packageOverview",
    SELECT_OVERRIDE_CATEGORY: "/selectCategory",
    SELECT_TARGET_STATUS: "/selectTargetStatus",
    PACKAGE_DETAILS: "/packageDetails",
    RECIPIENT_INFORMATION: "/recipientInformation"
}