import {axiosGET, axiosPOST} from "../../utils/Network/NetworkInterface";
import {DOLPHIN_APP} from "../views/DriverSummaryView/Constants";
import {driftEndpoints} from "../../constants/endpoints";
import {METRICS} from "../../constants/Metrics";
import {Modules} from "../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";

export async function fetchTransporterByBadgeId(debriefDriverActions, appNotification, badgeId) {
    let startTime = Date.now();
    debriefDriverActions.isLoading()
    const data = {
        employeeBadgeId: badgeId
    }
    const response = await axiosPOST(METRICS.GET_TRANSPORTER_BY_BADGE_ID,
        driftEndpoints.GET_TRANSPORTER_BY_BADGE_ID, data, debriefDriverActions, appNotification)
        .catch((_) => {
            debriefDriverActions.getTransporterByBadgeIdErrorResponse();
        })
    if (response && response.status === 200) {
        MobileAnalyticsHelper.executeAPIAnalytics(Modules.GET_TRANSPORTER_BY_BADGE_ID, startTime);
        debriefDriverActions.getTransporterByBadgeIdResponse(response.data.transporter)
        return;
    }
    MobileAnalyticsHelper.executeAPIAnalytics(Modules.GET_TRANSPORTER_BY_BADGE_ID, startTime, true);
}

export async function searchTransporterBykeyWord(debriefDriverActions, appNotification, keyword) {
    let startTime = Date.now();
    const data = {
        params: {
            keyword: keyword
        }
    }
    const response = await axiosGET(METRICS.SEARCH_TRANSPORTER_BY_KEYWORD,
        driftEndpoints.SEARCH_TRANSPORTER_BY_KEYWORD, data, debriefDriverActions, appNotification)
    if (response.status === 200) {
        MobileAnalyticsHelper.executeAPIAnalytics(Modules.SEARCH_TRANSPORTER_BY_KEYWORD, startTime);
        debriefDriverActions.searchDriverResponse(response.data.searchedTransporters)
        return;
    }
    MobileAnalyticsHelper.executeAPIAnalytics(Modules.SEARCH_TRANSPORTER_BY_KEYWORD, startTime, true);
}

export async function getDebriefDetails(debriefDriverActions, appNotification, transporterData) {
    let startTime = Date.now();
    debriefDriverActions.isLoading()

    const data = {
        companyId: transporterData.companyId,
        entityId: transporterData.transporterId,
        entityType: "DA"
    }

    const response = await axiosPOST(METRICS.GET_DEBRIEF_DETAILS, driftEndpoints.GET_DEBRIEF_DETAILS,
        data, debriefDriverActions, appNotification)
    if (response.status === 200) {
        MobileAnalyticsHelper.executeAPIAnalytics(Modules.GET_DEBRIEF_DETAILS, startTime);
        debriefDriverActions.getDebriefDetailsResponse(response.data.bucketDetails)
        return;
    }
    MobileAnalyticsHelper.executeAPIAnalytics(Modules.GET_DEBRIEF_DETAILS, startTime, true);
}

export async function bulkTranferPackages(debriefDriverActions, appNotification, requestData) {
    let startTime = Date.now();
    debriefDriverActions.isLoading()
    const {transporterId, packageDetailsList} = requestData

    const data = {
        includeShipmentDetails: true,
        packageQualifierList: packageDetailsList,
        source: DOLPHIN_APP,
        transporterId: transporterId
    }

    const response = await axiosPOST(METRICS.BULK_RECEIVE_PACKAGE, driftEndpoints.BULK_RECEIVE_PACKAGE,
        data, debriefDriverActions, appNotification)

    if (response.status === 200) {
        MobileAnalyticsHelper.executeAPIAnalytics(Modules.BULK_RECEIVE_PACKAGE, startTime);
        debriefDriverActions.bulkReceivePackageResponse(response.data.responseStatus)
        return;
    }
    MobileAnalyticsHelper.executeAPIAnalytics(Modules.BULK_RECEIVE_PACKAGE, startTime, true);
}
