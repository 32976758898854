import React, {useContext, useEffect} from "react";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {DebriefDriverContext} from "../context/DebriefDriverContext";
import {navigate} from "../../constants/routes";
import {FormattedMessage} from "react-intl";
import {AppContext} from "../../appContext/context/AppContext";
import {interceptBackButton, interceptBackButtonStop} from "../../Handler/BackPressHandler";
import {PlayerContext} from "../../player/PlayerContext";
import {ApplicationActions} from "../../player/ApplicationActions";

const ReturnCompleteView = (props) => {
    const {debriefDriverActions} = useContext(DebriefDriverContext);
    const {appActions} = useContext(AppContext)
    const {dispatchPlayer} = useContext(PlayerContext);

    const driverSummary = () => {
        dispatchPlayer({type: ApplicationActions.DEBRIEF_COMPLETE});
        debriefDriverActions.setDebriefComplete()
        props.history.push(navigate.DRIVER_SUMMARY)
    }

    const onBackPressed = () => {
        appActions.openBackDrop()
    }

    useEffect(() => {
        appActions.setBackPress(onBackPressed)
        interceptBackButton();
        return () => {
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])

    return (
        <Column height="100%" heights="fill" spacingInset="medium">
            <Column alignmentHorizontal="center" spacingInset="small">
                <Text type="h200"><FormattedMessage id="debrief_complete" /></Text>
                <Text type="b300"><FormattedMessage id="you_are_so_quick_great_work" /></Text>
                <img src={require('../../../images/ic_return_complete.png')} alt="Successful debrief"
                     style={{width: '80%', height: "auto"}}/>
            </Column>
            <Column alignmentHorizontal="center" alignmentVertical="bottom">
                <Row width="100%" widths="fill" alignmentVertical="bottom">
                    <Button onClick={driverSummary} size="large" type="primary"><FormattedMessage id="btn_view_return_summary" /></Button>
                </Row>
            </Column>
        </Column>
    )
}

export default ReturnCompleteView;
