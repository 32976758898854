import React, {useContext, useEffect} from 'react';
import {Loader, ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import {DebriefDriverContext} from "../../context/DebriefDriverContext";
import Column from "@amzn/meridian/column";
import {debriefDriverInit} from "./constants";
import {AppContext} from "../../../appContext/context/AppContext";
import {interceptBackButton, interceptBackButtonStop} from "../../../Handler/BackPressHandler";
import {navigate} from "../../../constants/routes";
import NotificationView from "../../../appContext/views/NotificationView";
import {PlayerContext} from "../../../player/PlayerContext";
import {ApplicationActions} from "../../../player/ApplicationActions";
import {PlayerConstants} from "../../../player/PlayerConstant";

const DebriefDriverView = (props) => {
    const {state: {
        isLoading,
        requestCompleted
    }, debriefDriverActions} = useContext(DebriefDriverContext)
    const {appActions} = useContext(AppContext)
    const {dispatchPlayer} = useContext(PlayerContext);

    const onBackPressed = () => {
        appActions.openBackDrop()
    }

    const onDriverBadgeIdScan = (badgeId) => {
        debriefDriverActions.getTransporterByBadgeId(badgeId, debriefDriverActions, appActions.setNotification)
    }

    useEffect(() => {
        if(requestCompleted) {
            debriefDriverActions.resetRequest();
            props.history.push(navigate.DRIVER_CONFIRM);
        }
    }, [requestCompleted])

    useEffect(() => {
        dispatchPlayer({type: ApplicationActions.TASK_SELECTED, data: {taskId: PlayerConstants.DEBRIEF_DRIVER}});
        debriefDriverActions.activateDriverDebriefWorkflow()
        appActions.setBackPress(onBackPressed)
        interceptBackButton();
        return () => {
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])

    return (
        <Column height="100%" spacingInset="medium">
            <NotificationView/>
            {isLoading && <Loader />}
            <ScanWithCodeView
                {...debriefDriverInit(props)}
                onSubmit={onDriverBadgeIdScan}
                focusLock={true}/>
        </Column>
    )
}


export default DebriefDriverView;
