import React from "react"
import { NotificationType} from "../../constants/constants";
import {FormattedMessage} from "react-intl";
import {getDriftServiceBaseURL} from "./BaseURLUtils";
import {DolphinAPIClientProvider, PUMAMetricHelper} from "@amzn/dolphin-web-framework";

const DriftServiceEndpoint = "/driftservice/";

export function getDriftClient() {
    const BASE_URL = getDriftServiceBaseURL() + DriftServiceEndpoint;
    DolphinAPIClientProvider.generateDolphinAPIClient(BASE_URL);
    return DolphinAPIClientProvider.dolphinAPIClient;
}

export async function axiosPOST(metricName, path, requestData, requesterState, appNotification) {
    let startTime = Date.now();
    try {
        PUMAMetricHelper.publishCountToDolphinCSM(metricName);
        const response = await getDriftClient().post(path, requestData);
        PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, metricName);
        return response;
    } catch(error) {
        requesterState.loadingComplete()
        appNotification(NotificationType.ERROR,
            <FormattedMessage id="receive_service_error"/>)
        publishErrorMetric(metricName, startTime, error);
        throw error;
    }
}

export async function axiosGET(metricName, path, params, requesterState, appNotification) {
    let startTime = Date.now();
    try {
        PUMAMetricHelper.publishCountToDolphinCSM(metricName);
        const response = await getDriftClient().get(path, params);
        PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, metricName);
        return response;
    } catch(error) {
        requesterState.loadingComplete()
        appNotification(NotificationType.ERROR,
            <FormattedMessage id="receive_service_error"/>)
        publishErrorMetric(metricName, startTime, error);
        throw error;
    }
}

export function publishErrorMetric(metricName, startTime, error) {

    //Emitting Failure Metrics
    PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, metricName);
    PUMAMetricHelper.publishCountToDolphinCSM(metricName+"-failure");
    if (error.status >= 400 && error.status < 500) {
        PUMAMetricHelper.publishCountToDolphinCSM("4xxError");
    }
    else {
        PUMAMetricHelper.publishCountToDolphinCSM("5xxError");
    }
}